import html2canvas from "html2canvas";
import pdfMake from 'pdfmake/build/pdfmake';
import vfs_fonts from 'pdfmake/build/vfs_fonts'; 
import { COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_NAME} from "../../constants/constants.js";
import { CalculateOrderSummary, removeReduntantBusinessNameFromAddress } from "../../utils/utilities.js";

const PrintInvoice = ({order}) => {
  const MIN_ROWS = 10; // Set your desired minimum number of rows

  const { addressInfo, orderItems, orderNumber, customerName, isCR } = order;

  const filledOrderItems = [...orderItems];
  while (filledOrderItems.length < MIN_ROWS) {
    filledOrderItems.push({}); // Add empty objects to fill the rows
  }

  /*
  const handleDownload = async () => {
    const mainElement = document.getElementById("pdf-main");

    // Create a temporary container for the PDF content
    const tempContainer = document.createElement("div");
    tempContainer.style.maxWidth = '100%';
    tempContainer.innerHTML = mainElement.innerHTML;

    // Extract header content
    const headerElement = document.getElementById("pdf-header");

    // Clone header to the temporary container
    const headerClone = headerElement.cloneNode(true);
    tempContainer.prepend(headerClone);

    document.body.appendChild(tempContainer);

    const opt = {
      margin: [10, 10, 20, 10], // Set margins for the PDF
      filename: `invoice_${order?._id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 }, // Increase the scale factor
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    try {
      const pdf = await html2pdf().from(tempContainer).set(opt).toPdf().get('pdf');

      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Set the width of the tempContainer and header to match the PDF page width
      tempContainer.style.width = `${pageWidth}mm`;
      headerClone.style.width = `${pageWidth}mm`;

      // Convert header to canvas
      const headerCanvas = await html2canvas(headerClone, { scale: 2 });

      // Convert canvas to image
      const headerImage = headerCanvas.toDataURL('image/jpeg');

      // Add header to the first page
      pdf.setPage(1);
      //pdf.addImage(headerImage, 'JPEG', 10, 10, pageWidth - 20, (headerCanvas.height / headerCanvas.width) * (pageWidth - 20)); // Maintain aspect ratio

      // Add rows one by one and handle page breaks
      const rows = tempContainer.querySelectorAll("#pdf-main tbody tr");
      let cursorY = 10; // Initial cursor position after header

      for (const row of rows) {
        const rowCanvas = await html2canvas(row, { scale: 2 });
        const rowImage = rowCanvas.toDataURL('image/jpeg');
        const rowHeight = (rowCanvas.height / rowCanvas.width) * (pageWidth - 20);
        if (cursorY + rowHeight > pageHeight - 40) { // Check if the row exceeds the page height
          pdf.addPage();
          //pdf.addImage(headerImage, 'JPEG', 10, 10, pageWidth - 20, (headerCanvas.height / headerCanvas.width) * (pageWidth - 20)); // Add header to new page
          cursorY = 50; // Reset cursor position for new page
        }

        pdf.addImage(rowImage, 'JPEG', 10, cursorY, pageWidth - 20, rowHeight); // Add row to PDF
        cursorY += rowHeight + 10; // Update cursor position
      }

      // Add footer and page numbers
      for (let i = 1; i <= pdf.internal.getNumberOfPages(); i++) {
        pdf.setPage(i);
        pdf.text(`Page ${i} of ${pdf.internal.getNumberOfPages()}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      }

       // Download the PDF
       //pdf.save(`packingslip_${order?._id}.pdf`);
       //console.log('PDF downloaded.');
      // Finalize
      pdf.output();

      // Open the PDF in a new tab
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      window.open(pdfUrl);
      console.log('Opening PDF in a new tab...');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the temporary container after generating the PDF
      document.body.removeChild(tempContainer);
      console.log('Temporary container removed.');
    }
  };
  */
/*
  pdfMake.fonts = {
    NotoSansSC: {
      normal: 'NotoSansSC-Regular.ttf',
      bold: 'NotoSansSC-Bold.ttf',
      italics: 'NotoSansSC-Regular.ttf', // Use regular for italics if not available
      bolditalics: 'NotoSansSC-Bold.ttf' // Use bold for bolditalics if not available
    }
  };

  //pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const loadFonts = async () => {
    const regularFont = await fetch('/fonts/NotoSansSC-Regular.ttf').then(res => res.arrayBuffer()).then(buf => new Uint8Array(buf));
    const boldFont = await fetch('/fonts/NotoSansSC-Bold.ttf').then(res => res.arrayBuffer()).then(buf => new Uint8Array(buf));
    pdfMake.vfs = {
      ...pdfFonts.pdfMake.vfs,
      'NotoSansSC-Regular.ttf': regularFont,
      'NotoSansSC-Bold.ttf': boldFont
    };
  };
  */
  const loadFonts = async () => {
    try {
      // Assign the fonts to pdfMake
      pdfMake.vfs = vfs_fonts.vfs;
      // Configure pdfMake to use the fonts
      pdfMake.fonts = {
        NotoSansSC: {
          normal: 'NotoSansSC-Regular.ttf',
          bold: 'NotoSansSC-Bold.ttf',
          medium: 'NotoSansSC-Medium.ttf',
        }
      };
      
      // Log the font configuration to verify
      console.log('Font configuration:', pdfMake.fonts);
  
      console.log('Fonts loaded successfully');
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };

  // Function to calculate header image dimensions
  function calculateHeaderImageDimensions(headerCanvas, pageMargins) {
    const pageWidth = 595.28; // A4 width in points (8.27 inches * 72 points per inch)
    const totalMargins = pageMargins[0] + pageMargins[2]; // left + right margins
    const headerImageWidth = pageWidth - totalMargins;
    const headerImageHeight = (headerCanvas.height / headerCanvas.width) * headerImageWidth;
    return { headerImageWidth, headerImageHeight };
  }
  

  const handleDownload = async () => {
    await loadFonts();
  
    const headerElement = document.getElementById("pdf-header");
  
    // Convert header to canvas
    const headerCanvas = await html2canvas(headerElement, { scale: 2 });
    const headerImage = headerCanvas.toDataURL('image/jpeg');
  
    // Define page margins
    const pageMargins = [20, 60, 20, 10]; // Adjust the top margin to account for the header
  
    // Calculate header image dimensions
    const { headerImageWidth, headerImageHeight } = calculateHeaderImageDimensions(headerCanvas, pageMargins);
    let {total} = CalculateOrderSummary(orderItems);
    if (isCR) {
      total = -total;
    }
    const totalAmount = total ? total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0;
 
    // Define the table structure
    const tableBody = [
      [
        { text: 'Quantity', style: 'tableHeader', alignment: 'center' },
        { text: 'Item Code', style: 'tableHeader', alignment: 'center' },
        { text: 'Description', style: 'tableHeader', alignment: 'center' },
        { text: 'Price Each', style: 'tableHeader', alignment: 'center' },
        { text: 'Amount', style: 'tableHeader', alignment: 'center' }
      ],
      ...filledOrderItems.map(item => [
        { text: item.finalQuantity !== undefined ? (isCR ? -item.finalQuantity : item.finalQuantity) : ' ', alignment: 'right' },
        { text: item.finalQuantity !== undefined ? item.product?.sku : ' ', alignment: 'right' },
        { text: item.finalQuantity !== undefined ? `${item.desc ? item.desc : ''}` : ' ', alignment: 'left' },
        { text: item.finalQuantity !== undefined ? item.finalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ' ', alignment: 'right' },
        { text: item.finalQuantity !== undefined ? (isCR ? -item.finalPrice * item.finalQuantity : item.finalPrice * item.finalQuantity)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ' ', alignment: 'right' }
      ]),
      [
        { text: ' ', 
          colSpan: 3, 
          border: [true, false, true, true], 
        },
        { text: ' '},
        { text: ' '},
        { text: [
            { text: 'TOTAL:', fontSize: 14, bold: true }
          ],
          colSpan: 1, 
          border: [true, false, false, true], 
          fontSize: 14, 
          bold: true, 
          alignment: 'left' 
        },
        { text: [
            { text: `$${totalAmount}`, fontSize: 14, bold: true }
          ],
          border: [false, false, true, true], 
          fontSize: 14, 
          bold: true, 
          alignment: 'right'
        }
      ]
    ];
  
    // Define the document
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [20, headerImageHeight + 70, 20, 125],
      defaultStyle: {
        font: 'NotoSansSC',
        fontSize: 12 // Adjust the font size
      },
      header: function(currentPage, pageCount) {
        return {
          columns: [
            {
              width: '*',
              text: ''
            },
            {
              image: headerImage,
              width: headerImageWidth, // Fixed width
              height: headerImageHeight,
              alignment: 'center',
              margin: [0, 10, 0, 10] // Margin around the header image
            },
            {
              width: '*',
              text: ''
            }
          ],
          margin: [0, 10, 0, 10] // Margin around the header container
        };
      },
      content: [
        {
          table: {
            headerRows: 1,
            widths: ['15%', '15%', '40%', '15%', '15%'],
            body: tableBody
          },
          layout: {
            hLineWidth: function(i, node) {
              return (i === 0 || i === node.table.body.length - 1 || i === 1  || i >= node.table.body.length - 1) ? 1 : 0; // Only draw horizontal lines for the header and footer
            },
            vLineWidth: function(i, node) {
              return 1; // Draw vertical lines for all cells
            },
            hLineColor: function(i, node) {
              return '#000000'; // Black color for horizontal lines
            },
            vLineColor: function(i, node) {
              return '#000000'; // Black color for vertical lines
            }
          }
        }
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black',
          fillColor: '#f2f2f2',
          alignment: 'center',
        }
      },
      footer: function(currentPage, pageCount) {
        return {
          stack: [
            {
              table: {
                widths: ['100%'],
                body: [
                  [
                    {
                      text: 'The perishable agricultural commodities listed on this invoice are sold subject to the statutory trust authorized by section 5(c) of the Perishable Agricultural Commodities Act, 1930 (7 U.S.C. 499e(c)). The seller of these commodities retains a trust claim over these commodities, all inventories of food or other products derived from these commodities, and any receivables or proceeds from the sale of these commodities until full payment is received.',
                      border: [true, true, true, true],
                      fontSize: 8
                    }
                  ],
                  [
                    {
                      text: `Page ${currentPage} of ${pageCount}`,
                      border: [false, false, false, false],
                      alignment: 'center',
                      margin: [0, 10, 0, 0] // Adjust margin to ensure proper spacing
                    }
                  ]
                ]
              },
              margin: [20, 0, 20, 10] // Margin around the footer table
            }
          ]
        };
      }
    };
  
    // Generate the PDF and open it in a new tab
    pdfMake.createPdf(docDefinition).open();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const formattedOrderCreateTime = new Date(order.orderDate).toLocaleDateString();
  const formattedBillPhoneNumber = formatPhoneNumber(addressInfo?.phone);
  const formattedShipPhoneNumber = formatPhoneNumber(addressInfo?.phone);

  let { total, units } = CalculateOrderSummary(orderItems);
  if (isCR) {
    total = -total;
  }
  const totalAmount = total ? total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0;
  return (
      <div className="fullscreen-container">
        <div className="order-invoice  justify-content-center  my-5">
          <div className="row d-flex justify-content-center mb-5">
            <button className="btn btn-success col-3" onClick={() => {handleDownload()}}>
              <i className="fa fa-print"></i>&nbsp;{isCR ? 'Print Credit Memo' : 'Print Invoice'}
            </button>
          </div>
          {/* Invoice */}
          <div id="order_invoice" className="p-3 border border-secondary" style={{ marginTop: '40px', display: 'flex', justifyContent: 'center'  }}>
            <header className="clearfix">
              {/* Top area */}
              <div id='pdf-header' className="text-center">
                <div className="d-flex justify-content-between align-items-center">
                  {/* Company info */}
                  <div id="company" style={{width: '35%', textAlign: 'left'}}>
                    <div style={{ fontSize: '3.0em', fontWeight: 'bold', color: '#333' }}>{COMPANY_NAME}</div>
                    <div>
                      <text style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_LINE1}</text>,
                      <br />
                      <text style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_LINE2}</text>,
                    </div>
                    <div style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_PHONE}</div>
                    <div style={{fontSize: '2.5em'}}>
                      <a href="mailto:sales@tomproduce.com">sales@tomproduce.com</a>
                    </div>
                  </div>
                  {/* Company logo */}
                  <div id="logo" style={{width: '30%', marginTop: '-15px', textAlign: 'center'}}>
                    <img src="/images/TomProduce_logo.jpg" alt="Company Logo" style={{ height: 'auto', width: '8vw' }}/>
                  </div>
                  {/* Invoice info */}
                  <div style={{width: '35%', textAlign: 'right'}}>
                    <text style={{ textAlign: 'right', fontSize: '4.0em' }}>{ isCR ? 'CREDIT MEMO' : 'INVOICE' }</text>
                    <table className="table centered-table text-center" style={{ tableLayout: 'fixed', width: '100%' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '50%', fontSize: '2.6em' }}>Date</th>
                          <th style={{ width: '50%', fontSize: '2.6em'  }}>Invoice #</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{fontSize: '2.6em' }}>{formattedOrderCreateTime}</td>
                          <td style={{fontSize: '2.6em' }}>{orderNumber}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* End of Top area */}
                {/* Customer area */}
                <div className="row py-5" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{width: '48%'}}>
                    <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000', fontSize: '2.5em'  }}>Bill To</th></tr>
                      </thead>
                      <tbody>
                        <tr><td style={{ border: 'none' , fontSize: '3.0em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left'  }}>{customerName}</td></tr>
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left'  }}>{removeReduntantBusinessNameFromAddress(customerName, addressInfo?.billingAddress)}</td></tr>                
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left' }}>{formattedBillPhoneNumber}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{width: '48%'}}>
                    <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000', fontSize: '2.5em'  }}>Ship To</th></tr>
                      </thead>
                      <tbody>
                        <tr><td style={{ border: 'none' , fontSize: '3.0em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{customerName}</td></tr>
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{removeReduntantBusinessNameFromAddress(customerName, addressInfo?.address)}</td></tr>                 
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{formattedShipPhoneNumber}</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* End of Customer area */}
                {/* Extra info */}
                <div>
                  <table className="table centered-table">
                    <thead>
                      <tr>
                        <th style={{fontSize: '2.4em'}}>P.O. Number</th>
                        <th style={{fontSize: '2.4em'}}>Terms</th>
                        <th style={{fontSize: '2.4em'}}>Rep</th>
                        <th style={{fontSize: '2.4em'}}>Ship</th>
                        <th style={{fontSize: '2.4em'}}>Via</th>
                        <th style={{fontSize: '2.4em'}}>F.O.B.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{fontSize: '2.4em'}}>{ order.PONumber }</td>
                        <td style={{fontSize: '2.4em'}}>{ order.terms } </td>
                        <td style={{fontSize: '2.4em'}}>{ order.salesRep}</td>
                        <td style={{fontSize: '2.4em'}}>{formattedOrderCreateTime}</td>
                        <td style={{fontSize: '2.4em'}}>{` `}</td>
                        <td style={{fontSize: '2.4em'}}>{ ` `}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* End of Extra info */}
              </div>
              {/* End of Heaedr */}

              {/* Order items */}
              <div id='pdf-main'>
                <main>
                <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse', padding: '8px'}}>
                  <thead>
                    <tr>
                      <th style={{ width: '8%',  border: '1px solid #000', textAlign: 'center', padding: '10px'}}>Quantity</th>
                      <th style={{ width: '25%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Item Code</th>
                      <th style={{ width: '37%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Description</th>
                      <th style={{ width: '15%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Price Each</th>
                      <th style={{ width: '15%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filledOrderItems.map((item, index) => (
                      <tr key={index} style={{ border: 'none' }}>
                        {item.finalQuantity !== undefined ? (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            { isCR ? -item.finalQuantity : item.finalQuantity }
                          </td>
                        ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 
                        {item.finalQuantity !== undefined ? (
                        <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ fontWeight: 'lighter', fontSize: 'smaller' }}>{item.product?.sku}</span>
                          </div>
                        </td>
                         ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 

                        {item.finalQuantity !== undefined ? (
                          <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px'}}>
                          {`${item.desc ? item.desc : ''}`}
                          </td>
                         ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 

                        { item.finalQuantity !== undefined ? (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            {item.finalPrice}
                          </td>
                         ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 

                        {item.finalQuantity !== undefined ? (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            {item && item.finalPrice && item.finalQuantity !== undefined ? ((isCR ? (-item.finalPrice * item.finalQuantity) : item.finalPrice * item.finalQuantity) ?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : ' '}
                          </td>
                         ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 
                      </tr>
                    ))}
                    <tr>
                      <td colspan="3" style={{border: '1px solid #000', paddingLeft: '10px', paddingRight: '10px', fontSize: '0.8em', borderBottom: 'none'}}>
                      </td>     
                      <td colSpan="2" style={{ border: '1px solid #000', fontSize: '1.2em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px', borderBottom: 'none'}}>
                        <text>UNITS:</text>
                        <span style={{ float: 'right' }}>{units ? units.toFixed(2) : 0}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style={{border: '1px solid #000', paddingLeft: '10px', paddingRight: '10px', fontSize: '0.8em', borderBottom: 'none', borderTop: 'none'}}>
                      </td>     
                      <td colSpan="2" style={{ border: '1px solid #000', fontSize: '1.2em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px', borderBottom: 'none', borderTop: 'none'}}>
                        <text>PALLETS:</text>
                        <span style={{ float: 'right' }}>{order && order.pallet ? order.pallet.toFixed(2) : 0}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style={{border: '1px solid #000', paddingLeft: '10px', paddingRight: '10px', fontSize: '0.8em', borderTop: 'none'}}>
                       
                      </td>     
                      <td colSpan="2" style={{ border: '1px solid #000', fontSize: '1.5em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px'}}>
                        <b>TOTAL:</b>
                        <span style={{ float: 'right' }}>${totalAmount}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </main>
              </div>
            </header>
          </div>
          {/* End of Invoice */}
        </div>
      </div>
  );
}
export default PrintInvoice;
