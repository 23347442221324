import html2canvas from "html2canvas";
import pdfMake from 'pdfmake/build/pdfmake';
import vfs_fonts from 'pdfmake/build/vfs_fonts'; 
import { COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_NAME} from "../../constants/constants.js";
import { removeReduntantBusinessNameFromAddress } from "../../utils/utilities.js";

const PrintPackingSlip = ({order}) => {
  const MIN_ROWS = 10; // Set your desired minimum number of rows

  const { customerName, orderItems, orderNumber, addressInfo } = order;

  /*
  pdfMake.fonts = {
    NotoSansSC: {
      normal: 'NotoSansSC-Regular.ttf',
      bold: 'NotoSansSC-Bold.ttf',
      italics: 'NotoSansSC-Regular.ttf', // Use regular for italics if not available
      bolditalics: 'NotoSansSC-Bold.ttf' // Use bold for bolditalics if not available
    }
  };
  */

  //pdfMake.vfs = pdfFonts.pdfMake.vfs;
  /*
  const loadFonts = async () => {
    const regularFont = await fetch('/fonts/NotoSansSC-Regular.ttf').then(res => res.arrayBuffer()).then(buf => new Uint8Array(buf));
    const boldFont = await fetch('/fonts/NotoSansSC-Bold.ttf').then(res => res.arrayBuffer()).then(buf => new Uint8Array(buf));
    pdfMake.vfs = {
      ...pdfFonts.pdfMake.vfs,
      'NotoSansSC-Regular.ttf': regularFont,
      'NotoSansSC-Bold.ttf': boldFont
    };
  };
  */
  const loadFonts = async () => {
    try {
      // Assign the fonts to pdfMake
      pdfMake.vfs = vfs_fonts.vfs;
      // Configure pdfMake to use the fonts
      pdfMake.fonts = {
        NotoSansSC: {
          normal: 'NotoSansSC-Regular.ttf',
          bold: 'NotoSansSC-Bold.ttf',
          medium: 'NotoSansSC-Medium.ttf',
        }
      };
      
      // Log the font configuration to verify
      console.log('Font configuration:', pdfMake.fonts);
  
      console.log('Fonts loaded successfully');
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };

  // Function to calculate header image dimensions
  function calculateHeaderImageDimensions(headerCanvas, pageMargins) {
    const pageWidth = 595.28; // A4 width in points (8.27 inches * 72 points per inch)
    const totalMargins = pageMargins[0] + pageMargins[2]; // left + right margins
    const headerImageWidth = pageWidth - totalMargins;
    const headerImageHeight = (headerCanvas.height / headerCanvas.width) * headerImageWidth;
    return { headerImageWidth, headerImageHeight };
  }
  

  const handleDownload = async () => {
    await loadFonts();
  
    const headerElement = document.getElementById("pdf-header");
  
    // Convert header to canvas
    const headerCanvas = await html2canvas(headerElement, { scale: 2 });
    const headerImage = headerCanvas.toDataURL('image/jpeg');
  
    // Define page margins
    const pageMargins = [20, 60, 20, 10]; // Adjust the top margin to account for the header
  
    // Calculate header image dimensions
    const { headerImageWidth, headerImageHeight } = calculateHeaderImageDimensions(headerCanvas, pageMargins);
 
    // Define the table structure
    const tableBody = [
      [
        { text: 'Quantity', style: 'tableHeader', alignment: 'center' },
        { text: 'Description', style: 'tableHeader', alignment: 'center' },
      ],
      ...filledOrderItems.map(item => [
        { text: item.finalQuantity !== undefined ? item.finalQuantity : ' ', alignment: 'right' },
        { text: item.finalQuantity !== undefined ? `${item.desc ? item.desc : ''} ${item.note ? `(${item.note})` : ''}` : ' ', alignment: 'left' },
      ]),
    ];
  
    // Define the document
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [20, headerImageHeight + 40, 20, 90],
      defaultStyle: {
        font: 'NotoSansSC',
        fontSize: 13 // Adjust the font size
      },
      header: function(currentPage, pageCount) {
        return {
          columns: [
            {
              width: '*',
              text: ''
            },
            {
              image: headerImage,
              width: headerImageWidth, // Fixed width
              height: headerImageHeight,
              alignment: 'center',
              margin: [0, 10, 0, 10] // Margin around the header image
            },
            {
              width: '*',
              text: ''
            }
          ],
          margin: [0, 10, 0, 10] // Margin around the header container
        };
      },
      content: [
        {
          table: {
            headerRows: 1,
            widths: ['30%', '70%'],
            body: tableBody
          },
          layout: {
            hLineWidth: function(i, node) {
              return (i === 0 || i === 1 || i === node.table.body.length ) ? 1 : 0; // Only draw horizontal lines for the header and footer
            },
            vLineWidth: function(i, node) {
              return 1; // Draw vertical lines for all cells
            },
            hLineColor: function(i, node) {
              return '#000000'; // Black color for horizontal lines
            },
            vLineColor: function(i, node) {
              return '#000000'; // Black color for vertical lines
            }
          }
        }
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          fillColor: '#f2f2f2',
          alignment: 'center',
        }
      },
      footer: function(currentPage, pageCount) {
        return {
          
            text: `Page ${currentPage} of ${pageCount}`,
            alignment: 'center',
            margin: [0, 10, 0, 10]
        };
      }
    };
  
    // Generate the PDF and open it in a new tab
    pdfMake.createPdf(docDefinition).open();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const filledOrderItems = [...orderItems];
  while (filledOrderItems.length < MIN_ROWS) {
    filledOrderItems.push({}); // Add empty objects to fill the rows
  }
  const formattedOrderCreateTime = new Date(order.orderDate).toLocaleDateString();
  const formattedBillPhoneNumber = formatPhoneNumber(addressInfo?.phone);
  const formattedShipPhoneNumber = formatPhoneNumber(addressInfo?.phone);

  return (
      <div className="fullscreen-container">
        <div className="order-invoice my-5  justify-content-center ">
          <div className="row d-flex justify-content-center mb-5">
            <button className="btn btn-success col-2" onClick={() => {handleDownload()}}>
               <i className="fa fa-print"></i>&nbsp;Print Packing Slip
            </button>
          </div>
          {/* Packing */}
          <div id="order_invoice" className="p-3 border border-secondary" style={{ marginTop: '40px', display: 'flex', justifyContent: 'center'  }}>
            <header className="clearfix">
              {/* Top area */}
              {/* Top area */}
              <div id='pdf-header' className="text-center">
                <div className="d-flex justify-content-between align-items-center">
                  {/* Company info */}
                  <div id="company" style={{width: '35%', textAlign: 'left'}}>
                    <div style={{ fontSize: '3.0em', fontWeight: 'bold', color: '#333' }}>{COMPANY_NAME}</div>
                    <div>
                      <text style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_LINE1}</text>,
                      <br />
                      <text style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_LINE2}</text>,
                    </div>
                    <div style={{fontSize: '2.5em'}}>{COMPANY_ADDRESS_PHONE}</div>
                    <div style={{fontSize: '2.5em'}}>
                      <a href="mailto:sales@tomproduce.com">sales@tomproduce.com</a>
                    </div>
                  </div>
                  {/* Company logo */}
                  <div id="logo" style={{width: '30%', marginTop: '-15px', textAlign: 'center'}}>
                    <img src="/images/TomProduce_logo.jpg" alt="Company Logo" style={{ height: 'auto', width: '8vw' }}/>
                  </div>
                  {/* Invoice info */}
                  <div style={{width: '35%', textAlign: 'right'}}>
                    <text style={{ textAlign: 'right', fontSize: '4.0em' }}>PACKING SLIP</text>
                    <table className="table centered-table text-center" style={{ tableLayout: 'fixed', width: '100%' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '50%', fontSize: '2.6em' }}>Date</th>
                          <th style={{ width: '50%', fontSize: '2.6em'  }}>Invoice #</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{fontSize: '2.6em' }}>{formattedOrderCreateTime}</td>
                          <td style={{fontSize: '2.6em' }}>{orderNumber}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* End of Top area */}
                {/* Customer area */}
                <div className="row py-5" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{width: '48%'}}>
                    <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000', fontSize: '2.5em'  }}>Bill To</th></tr>
                      </thead>
                      <tbody>
                        <tr><td style={{ border: 'none' , fontSize: '3.0em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left'  }}>{customerName}</td></tr>
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left'  }}>{removeReduntantBusinessNameFromAddress(customerName, addressInfo?.billingAddress)}</td></tr>                
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'left' }}>{formattedBillPhoneNumber}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{width: '48%'}}>
                    <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000', fontSize: '2.5em'  }}>Ship To</th></tr>
                      </thead>
                      <tbody>
                        <tr><td style={{ border: 'none' , fontSize: '3.0em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{customerName}</td></tr>
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{removeReduntantBusinessNameFromAddress(customerName, addressInfo?.address)}</td></tr>                 
                        <tr><td style={{ border: 'none' , fontSize: '2.8em', paddingLeft: '8px', paddingRight: '8px' , textAlign: 'left' }}>{formattedShipPhoneNumber}</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* End of Customer area */}
                {/* Extra info */}
                <div>
                  <table className="table centered-table">
                    <thead>
                      <tr>
                        <th style={{fontSize: '2.4em'}}>P.O. Number</th>
                        <th style={{fontSize: '2.4em'}}>Terms</th>
                        <th style={{fontSize: '2.4em'}}>Rep</th>
                        <th style={{fontSize: '2.4em'}}>Ship</th>
                        <th style={{fontSize: '2.4em'}}>Via</th>
                        <th style={{fontSize: '2.4em'}}>F.O.B.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{fontSize: '2.4em'}}>{ order.PONumber }</td>
                        <td style={{fontSize: '2.4em'}}>{ order.terms } </td>
                        <td style={{fontSize: '2.4em'}}>{ order.salesRep}</td>
                        <td style={{fontSize: '2.4em'}}>{formattedOrderCreateTime}</td>
                        <td style={{fontSize: '2.4em'}}>{` `}</td>
                        <td style={{fontSize: '2.4em'}}>{ ` `}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* End of Extra info */}
              </div>
              {/* End of Heaedr */}


              {/* Order items */}
              <div id='pdf-main'>
                <main>
                <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse', padding: '8px'}}>
                  <thead>
                    <tr>
                      <th style={{ width: '30%',  border: '1px solid #000', textAlign: 'center', padding: '10px'}}>Quantity</th>
                      <th style={{ width: '70%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filledOrderItems.map((item, index) => (
                      <tr key={index} style={{ border: 'none' }}>
                        {item.finalQuantity !== undefined ? (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            {item.finalQuantity}
                          </td>
                        ) : (
                          <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                            &nbsp;
                          </td>
                        )} 
                        {item.finalQuantity !== undefined && (
                          <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px'}}>
                            {`${item.desc ? item.desc : ''} ${item.note ? `(${item.note})` : ''}`}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                </main>
              </div>
            </header>
          </div>
          {/* End of Invoice */}
        </div>
      </div>
  );
}
export default PrintPackingSlip;
