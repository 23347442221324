
import React, { useState, useEffect, useRef  } from 'react';
import { ORDER_STATUS, ORDER_STATUS_ICON, ORDER_USER_RIGHTS, PURCHASE_STATUS, PURCHASE_STATUS_ICON, PURCHASE_USER_RIGHTS } from "../../../constants/constants.js";
import { valueIsANonNegativeNumber, valueIsANumber } from "../../../utils/validators";
import _, { has, set } from "lodash";
import { useLazyAdminGetVendorProductPriceListQuery } from "../../../redux/api/priceListAdminApi";
import { DetermineUserOrderRights, DetermineUserPurchaseRights } from "../../admin/AdminActionEligibilities";
import Loader from '../Loader';
import { ButtonCell, EditableNumberCell, RightAlignedCell } from '../CustomMDBDataTableCell';

import { VendorSelection } from "../../vendor/VendorSelection";
import { getSellPrice, SellPriceDoubleCheck } from '../../../utils/utilities';
import { CustomerSelection } from '../../customer/CustomerSelection';
import { ConfirmDialog } from '../../admin/ConfirmDialog';
import { toast } from 'react-toastify';

export const InventoryInOutBoundPopup = ({ onClose, product, title, data, dataTimestamp, vendors, customers, visibility, updateFunction, removeItemFunction, user, column }) => {
  const [adminGetVendorProductPriceListQuery, { data: vendorProductPriceListData, isLoading: loadingProductPriceListData }] = useLazyAdminGetVendorProductPriceListQuery();
  const [clonedDataTimestamp, setClonedDataTimestamp] = useState(0);
  const [clonedOrdersData, setClonedOrdersData] = useState([]);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const [needPopuplateVendorSuggestions, setNeedPopuplateVendorSuggestions] = useState(true);
  const popupRef = useRef(); // Create a ref

  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [vendorProductPriceHash, setVendorProductPriceHash] = useState({});

  useEffect(() => { 
    if (visibility === 'visible' && column === 'inbound') {
      adminGetVendorProductPriceListQuery(product._id);
    }
  }, [visibility]);

  // Add this useEffect hook
  useEffect(() => {
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // don't allow click below the popup
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("mouseup", handleClick);
    } else {
      document.removeEventListener("mouseup", handleClick);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClick);
    };
  }, [visibility]);
  
  useEffect(() => {
    if (data && dataTimestamp !== clonedDataTimestamp) {
      // iterate clonedOrdersData, check if there is matched data, then compare the updatedAt timestamp, if it is different, update the clonedOrdersData
      if (column === 'inbound' ) { 
        data.forEach((purchase) => {
          // Check if there is matched data based on the product
          //const matchedPurchase = clonedOrdersData.find(newPurchase => newPurchase.vendor && purchase.vendor && ((!newPurchase.purchaseId && newPurchase.vendor._id === purchase.vendor._id) || (newPurchase.purchaseId && newPurchase.purchaseId === purchase.purchaseId)));
          let matchedPurchaseIndex = clonedOrdersData.findIndex(newPurchase => purchase._id && newPurchase._id === purchase._id);
          // If no match, there is a possibility that the purchase is new, check if there is a match based on the vendor
          if (matchedPurchaseIndex === -1) {
            matchedPurchaseIndex = clonedOrdersData.findIndex(newPurchase => !newPurchase._id && newPurchase.vendor && purchase.vendor && newPurchase.vendor._id === purchase.vendor._id);
          }

          if (matchedPurchaseIndex !== -1) {
            const matchedPurchase = clonedOrdersData[matchedPurchaseIndex];
            // Compare the updatedAt timestamp
            if (!matchedPurchase.updatedAt || matchedPurchase.updatedAt < purchase.updatedAt) {
              // If the timestamp in data is newer, update the clonedOrdersData
              clonedOrdersData[matchedPurchaseIndex] = { ...purchase, ...matchedPurchase };
            }
          } else {
            clonedOrdersData.push(purchase);
          }
        });

        // Remove item if not in data
        /*
        clonedOrdersData.forEach((purchase, index) => {
          if (!data.some(newPurchase => newPurchase.vendor === purchase.vendor)) {
            clonedOrdersData.splice(index, 1);
          }
        });
        */
      } else {
        data.forEach((order) => {
          // Check if there is matched data based on the product
          //const matchedOrder = clonedOrdersData.find(newOrder => newOrder.customer && order.customer && ((!newOrder.orderId && newOrder.customer._id === order.customer._id) || (newOrder.orderId && newOrder.orderId === order.orderId)));
          let matchedOrderIndex = clonedOrdersData.findIndex(newOrder => order._id && newOrder._id === order._id);
          if (matchedOrderIndex === -1) {
            matchedOrderIndex = clonedOrdersData.findIndex(newOrder => !newOrder._id && newOrder.customer && order.customer && newOrder.customer._id === order.customer._id);
          }

          if (matchedOrderIndex !== -1) {
            const matchedOrder = clonedOrdersData[matchedOrderIndex];
            // Compare the updatedAt timestamp
            if (!matchedOrder.updatedAt || matchedOrder.updatedAt < order.updatedAt) {
              // If the timestamp in data is newer, update the clonedOrdersData
              clonedOrdersData[matchedOrderIndex] = { ...order, ...matchedOrder };
            } 
          } else {
            clonedOrdersData.push(order);
          }
        });

        // REmove item if not in data
        /*
        clonedOrdersData.forEach((order, index) => {
          if (!data.some(newOrder => newOrder.customer === order.customer)) {
            clonedOrdersData.splice(index, 1);
          }
        });
        */
      }
      setClonedDataTimestamp(dataTimestamp);
    }
    if (column === 'inbound' && vendorProductPriceListData) {
      const priceHash = {};
      vendorProductPriceListData.forEach((item) => {
        priceHash[item.vendor] = item.price;
        if (needPopuplateVendorSuggestions && item.price) {
          const selectedVendor = vendors.find(vendor => vendor._id === item.vendor._id);
          // if vendor is not in the list, add it to the list
          if (!clonedOrdersData.some(purchase => purchase.vendor?._id === selectedVendor._id)) {
            clonedOrdersData.push({ status: ORDER_STATUS.PENDING, vendor: selectedVendor,  price: item.price });
          }
        }
      });
      setNeedPopuplateVendorSuggestions(false);

      setVendorProductPriceHash(priceHash);

      // Update the price new purchase order
      clonedOrdersData.forEach((item, index) => {
        if (item.vendor && (!item.price || item.price === "")) {
          item.price = priceHash[item.vendor._id];
        }
      });
    }
    setThereAreValueChanges(clonedOrdersData, data);
    setFormUpdated(!formUpdated);
  }, [vendorProductPriceListData, data]);
  //--------------------------------- Functions ---------------------------------
  // Update quantity of the orders
  /*
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrdersData[index].quantity !== value) {
      clonedOrdersData[index].quantity = Number(value);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };

  // Update note of the orders
  const handleNoteChange = (index, value) => {
    if (clonedOrdersData[index].note !== value) {
      clonedOrdersData[index].note = value;
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };

  // Update price of the orders
  const handlePriceChange = (index, value) => {
    //console.log("handlePriceChange", value, clonedOrdersData[index].price, data[index].price)
    if (valueIsANonNegativeNumber(value) && clonedOrdersData[index].price !== value) {
      clonedOrdersData[index].price = Number(value);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };  
  */
  const handleQuantityChange = (index, value) => {
    if (valueIsANumber(value) && clonedOrdersData[index].quantity !== Number(value)) {
      const updatedOrdersData = [...clonedOrdersData];
      updatedOrdersData[index] = {
        ...updatedOrdersData[index],
        quantity: Number(value),
      };
      setClonedOrdersData(updatedOrdersData);
      setThereAreValueChanges(!_.isEqual(updatedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };
  
  // Update note of the orders
  const handleNoteChange = (index, value) => {
    if (clonedOrdersData[index].note !== value) {
      const updatedOrdersData = [...clonedOrdersData];
      updatedOrdersData[index] = {
        ...updatedOrdersData[index],
        note: value,
      };
      setClonedOrdersData(updatedOrdersData);
      setThereAreValueChanges(!_.isEqual(updatedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };
  
  // Update price of the orders
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrdersData[index].price !== Number(value)) {
      const updatedOrdersData = [...clonedOrdersData];
      updatedOrdersData[index] = {
        ...updatedOrdersData[index],
        price: Number(value),
      };
      setClonedOrdersData(updatedOrdersData);
      setThereAreValueChanges(!_.isEqual(updatedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };

  const addItem = () => {
    const newItem = {
      status: ORDER_STATUS.PENDING,
      price: "",
    };
    clonedOrdersData.push(newItem);
    setFormUpdated(!formUpdated);
    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
  }

  const getVendorCustomerName = (index) => {
    if (index >= 0) {
      return column === 'inbound' ? clonedOrdersData[index].vendor?.name : clonedOrdersData[index].customer?.name;
    }
  }

  const removeItem = (index) => {
    if (getVendorCustomerName(index)) {
      showRemoveItemConfirmDialog(index);
    } else {
      // If the item is new, remove it directly
      confirmRemovingItem(index);
    }
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      if (column === 'inbound') {
        if (clonedOrdersData[index].purchaseId) {
          removeItemFunction?.remove({ itemRowId: clonedOrdersData[index]._id, productId: product._id, purchaseId: clonedOrdersData[index].purchaseId });
        }
      } else {
        if (clonedOrdersData[index].orderId) {
          removeItemFunction?.remove({ itemRowId: clonedOrdersData[index]._id,  productId: product._id, orderId: clonedOrdersData[index].orderId });
        }
      }
      clonedOrdersData.splice(index, 1);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));

      // Trigger remove query
      
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Handle vendor select
  const handleVendorSelect = (index, vendor) => {
    clonedOrdersData[index].vendor = vendor;

    // default value
    clonedOrdersData[index].quantity = 1;
    // load price
    clonedOrdersData[index].price =  vendorProductPriceHash && vendorProductPriceHash[vendor._id] ? vendorProductPriceHash[vendor._id] : "";

    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
    setFormUpdated(!formUpdated);
  }

  const handleCustomerSelect = (index, customer) => {
    clonedOrdersData[index].customer = customer;
    // default value
    clonedOrdersData[index].quantity = 1;
    clonedOrdersData[index].price = getSellPrice(product, customer.tier).price;
    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
    setFormUpdated(!formUpdated);
  }

  // Clone the inventory object
  let filteredVendor = [...vendors];
  let filteredCustomer = [...customers];
  // Remove vendors that already in the list
  if (column === 'inbound') {
    clonedOrdersData.forEach((order) => {
      filteredVendor = filteredVendor.filter(vendor => vendor._id !== order.vendor?._id);
    });
  } else if (column === 'outbound') {
    clonedOrdersData.forEach((order) => {
      filteredCustomer = filteredCustomer.filter(customer => customer._id !== order.customer?._id && customer._id !== '67109552c781aa4e6573f277');
    });
  }  

  // Update orders
  const updateHandler = (e) => {
    e.preventDefault();

    // There there are chages, update first
    if (thereAreValueChanges) {
      const ordersDataToUpdate = [];
      let hasError = false;
      clonedOrdersData?.forEach((item, index) => {
        if (item.price === undefined || item.quantity === undefined || valueIsANonNegativeNumber(item.price) === false || valueIsANumber(item.quantity) === false) {
          //toast.error(`Please enter price and quantity for ${column === 'inbound' ? order.vendor.name : order.customer.name}`);
          //hasError = true;
          return;
        } else if (column === 'outbound') {
          const { ok, message } = SellPriceDoubleCheck(user, product, item.price);
          if (!ok) {
            toast.error(message);
            hasError = true;
          }
        }

        // Find the original data
        const dataItem = data.find((originalItem) => {
          // Search for existing item by _id
          const matched = item._id === originalItem._id;
          if (matched) {  
            return true;
          }
        });

        // Only push to update the changed item
        if ((item.customer !== undefined || item.vendor !== undefined) && !_.isEqual(item, dataItem)) {
          const orderToUpdate = {
            itemRowId: item._id, // this is item row id, not order id
            orderId: item.orderId,
            purchaseId: item.purchaseId,
            finalPrice: item.price,
            finalQuantity: item.quantity,
            customer: item.customer,
            vendor: item.vendor,
            note: item.note,
          };
          ordersDataToUpdate.push(orderToUpdate);
        }
      });
      if (!hasError && ordersDataToUpdate.length > 0 && updateFunction) {
        updateFunction.update({ id: product._id, body: ordersDataToUpdate });
      }
    }
  };

  const popupStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };
  const total = () => {
    let total = 0;
    clonedOrdersData.forEach(item => {
      total += item.quantity ? item.quantity : 0;
    });
    return total;
  }

  const canUpdate = updateFunction !== null;

  return(
    <div ref={popupRef} id="popup" class="popup" style={{...popupStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div className="col-2"> 
          {updateFunction && (
            <button onClick={updateHandler} className="btn btn-success" disabled={!thereAreValueChanges || updateFunction?.updating}>
              Update
            </button>
          )}
        </div> 
        <div className="col-8 text-center"><h5>{title}</h5></div>
        <div className="col-2 text-end">
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div style={{ width: '96%', maxHeight: '80vh', overflow: 'overlay'}}>
        <table className="table table-bordered" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th className={'text-center'} style={{width: '5%'}}>Status</th>
              <th className={'text-center'} style={{width: '10%'}}>{column === 'inbound' ? 'Purchase #' : 'Order #'}</th>
              <th className={'text-center'} style={{width: '40%'}}>{column === 'inbound' ? 'Vendor' : 'Customer'}</th>
              <th className={'text-center'} style={{width: '24%'}}>Note</th>
              <th className={'text-center'} style={{width: '8%'}}>Quantity</th>
              <th className={'text-center'} style={{width: '8%'}}>Price</th>
              <th className={'text-center'} style={{width: '5%'}}>Action</th>
            </tr>
          </thead>

          <tbody>
            { clonedOrdersData && data && (
                clonedOrdersData.map((item, index) => {
                  let matchedData = data.find((originalItem) => {
                    // Search for existing item by _id
                    const matched = item._id && originalItem._id && item._id.toString() === originalItem._id.toString();
                    if (matched) {
                      return true;
                    }
                  });

                  if (!matchedData) {
                    matchedData = data.find((originalItem) => {
                      // Search for existing item by vendor or customer
                      const matched = item.vendor && originalItem.vendor && item.vendor._id === originalItem.vendor._id;
                      if (matched) {
                        return true;
                      }
                    });
                  }

                  // Check user rights
                  let editable = false;
                  let deletable = false;
                  if (column === 'inbound') {
                    const {rights} = DetermineUserPurchaseRights(user, item.status);
                    editable = canUpdate && rights?.some(right => right === PURCHASE_USER_RIGHTS.UPDATE);           
                    deletable = (canUpdate && rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE)) || !item.purchaseId;               
                  } else {
                    const {rights} = DetermineUserOrderRights(user, item.status);
                    editable = canUpdate && rights?.some(right => right === ORDER_USER_RIGHTS.UPDATE);
                    deletable = (canUpdate && rights?.some(right => right === ORDER_USER_RIGHTS.DELETE)) || !item.orderId;
                  }
                  const productOwner = column === 'inbound' ? item.vendor : item.customer;
                  const name = productOwner && productOwner.name ? `${productOwner?.name}` : undefined;
                  const price = item.price;
                  const id = column === 'inbound' ? item.purchaseNumber : item.orderNumber;
                  return(
                    <tr key={index} style={{width: '100%'}} >
                      {/* Status */}
                      <td className={'text-center'} style={{width: '5%'}}>
                        {column === 'outbound' ? (
                          <span  >
                            { ORDER_STATUS_ICON[item.status] && (<i className={ORDER_STATUS_ICON[item.status].icon}  style={{ color: ORDER_STATUS_ICON[item.status].color }} /> )}
                          </span>
                        ) : (
                          <span  >
                            { PURCHASE_STATUS_ICON[item.status] && (<i className={PURCHASE_STATUS_ICON[item.status].icon}  style={{ color: PURCHASE_STATUS_ICON[item.status].color }} /> )}
                          </span>
                        )
                        }
                      </td>

                      {/* ID */}
                      <td className={'text-center'} style={{width: '10%'}}>
                        <span>
                            { id }
                        </span>
                      </td>

                      {/* Name */}
                        { name ? (
                            <td className={'text-start'} style={{width: '40%'}}>
                              {name}
                            </td>             
                        ):(
                          <td className={'text-start'} style={{width: '40%'}}>
                            {column === 'inbound' && (
                              VendorSelection({ vendors: filteredVendor , onChange: (vendor)=> handleVendorSelect(index, vendor)})
                            )}
                            {column === 'outbound' && (
                              CustomerSelection({ customers: filteredCustomer , onChange: (customer)=> handleCustomerSelect(index, customer)})
                            )}
                          </td>
                        )}

                        {/* Note */}
                        <td className={'text-start'} style={{width: '24%'}}>
                        {editable ? 
                          (<EditableNumberCell 
                            type={'text'}
                            readOnly={!editable} 
                            value={item.note} onChange={(val) => {handleNoteChange(index, val)}} color={item.note !== matchedData?.note ? 'red' : 'black'}> 
                          </EditableNumberCell>
                          ):(
                            <RightAlignedCell value={item.quantity} />
                          )
                        }
                      </td>

                      {/* Quantiy */}
                      <td className={'text-end'} style={{width: '8%'}}>
                        {editable ? 
                          (<EditableNumberCell 
                            readOnly={!editable} 
                            value={item.quantity} onChange={(val) => {handleQuantityChange(index, val)}} color={item.quantity !== matchedData?.quantity ? 'red' : 'black'}> 
                          </EditableNumberCell>
                          ):(
                            <RightAlignedCell value={item.quantity} />
                          )
                        }
                      </td>

                      {/* Price */}
                      <td className={'text-end'} style={{width: '8%'}}>
                        <EditableNumberCell 
                          readOnly={!editable} 
                          value={price} onChange={(val) => {handlePriceChange(index, val)}} color={item.price !== matchedData?.price ? 'red' : 'black'}> 
                        </EditableNumberCell>
                      </td>
                      
                      {/* Action - remove - only can remove pending purchases*/}
                      <td className={'text-center'} style={{width: '5%'}}>
                        <span  >
                          {deletable && (
                            <ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {removeItem(index);}} > </ButtonCell>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              )
            }
          </tbody>
        </table>
      </div>
      {loadingProductPriceListData && (
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>
      )}
      <div className="row col-12 my-2">
        <div className="col-5"></div>
        <div className="col-2 text-center">
          {canUpdate && (
            <button onClick={addItem} className="btn btn-primary" disabled={updateFunction?.updating}>
              +
            </button>
          )}
        </div>
        <div className="col-5 text-end">
          <h5>Total Units: {total()}</h5>
        </div>
      </div>
      {(updateFunction?.updating || removeItemFunction?.removing) && 
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>
      }
      <ConfirmDialog message={`Are you sure to remove ${getVendorCustomerName(itemIndexToRemove)}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
    </div>
  );
};