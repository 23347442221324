import React, { useEffect, useRef, useState } from "react";
import { QUICKBOOKS_STATUS } from "../../constants/constants";

const VerifiedIcon = ({verified}) => {
    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <button
                type="button"
                id="quicbooksButton"
                style={{
                    height: "2.5em",
                    verticalAlign: "middle",
                    width: "2.5em",
                    padding: "0.5em",
                    border: "none",
                    background: "none",
                }}     
            >
                <img
                    src= {"/images/icons/checkmark_icon.png"}
                    style={{ height: "100%", width: "100%" }}
                />
            </button>
        </div>
    );
};

export default VerifiedIcon;
