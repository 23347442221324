import React, { useEffect, useState } from "react";
import 'jsPDF-AutoTable';
import html2canvas from "html2canvas";
import pdfMake from 'pdfmake/build/pdfmake';
import vfs_fonts from 'pdfmake/build/vfs_fonts'; 

import { ADMIN_MENU_ITEMS, COMPANY_ADDRESS_FAX, COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_NAME, PRODUCT_CATEGORIES_ON_PDF} from "../../constants/constants.js";

import { useSelector } from "react-redux";
import { useAdminGetProductsQuery } from "../../redux/api/productsAdminApi.js";
import Loader from "../layout/Loader.jsx";
import { EditableNumberCell } from "../layout/CustomMDBDataTableCell.jsx";
import { getPSTStartOfDay, getSellPrice } from "../../utils/utilities";
import MetaData from "../layout/MetaData.jsx";
import HeaderAdmin from "../layout/HeaderAdmin.jsx";
import AdminLayout from "../layout/AdminLayout.jsx";

const CustomerPriceList = () => {
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data: productListFromServer, isLoading: isLoadingProducts, error: errorLoadingProducts  } = useAdminGetProductsQuery({extraParams: 'withCategory,activeOnly,onPriceList'}, { refetchOnMountOrArgChange: true });
  
  const [clonedGroupedProducts, setClonedGroupedProducts] = useState([]);
  const [firstHalfCategories, setFirstHalfCategories] = useState([]);
  const [secondHalfCategories, setSecondHalfCategories] = useState([]);
  const [categoryOtherNames, setCategoryOtherNames] = useState({});
  const [productList, setProductList] = useState();

  const [editMode, setEditMode] = useState(true);
  const today = getPSTStartOfDay().toLocaleDateString();
  const nextWeek = new Date(getPSTStartOfDay().getTime() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString();
  const [dateRangeStr, setDateRangeStr] = useState(`${today} - ${nextWeek}`); 
  const [tier, setTier] = useState(1);

  useEffect(() => {
    if (errorLoadingProducts) {
      console.error('Error loading products:', errorLoadingProducts);
    }
  }, [errorLoadingProducts]);

  useEffect(() => {
    // Only load productList once
    if (productListFromServer && productList === undefined) {
      setProductList(JSON.parse(JSON.stringify(productListFromServer)));
    }
  }, [productListFromServer]);

  useEffect(() => {
    if (productList && productList.products) {
      // Group products by category
      const groupedProducts = productList.products.reduce((acc, product) => {
        const categoryId = product.category._id;
        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }
        let productCopy = JSON.parse(JSON.stringify(product))
        productCopy.price = getSellPrice(product, tier).price;
        if (productCopy.price === undefined) {
          productCopy.price = 'CALL';
        }
        acc[categoryId].push(productCopy);
        return acc;
      }, {});

      // Sort the products within each category by name
      Object.keys(groupedProducts).forEach(categoryId => {
        groupedProducts[categoryId].sort((a, b) => a.name.localeCompare(b.name));
      });

      setClonedGroupedProducts(JSON.parse(JSON.stringify(groupedProducts)));

      // Define table categories
      const _categories = Object.keys(groupedProducts);
      const categories = PRODUCT_CATEGORIES_ON_PDF.map(productCategory => {
        return _categories.find(category => category === productCategory.id);
      });

      let _firstHalfProductCount = 0;
      let _secondHalfProductCount = categories.reduce((acc, category) => acc + groupedProducts[category].length, 0) + categories.length;
      let _firstHalfCategories = [];
      let _secondHalfCategories = [];

      for (let category of categories) {
        const categoryProductCount = groupedProducts[category].length;
      
        if (_firstHalfProductCount <= _secondHalfProductCount) {
          _firstHalfCategories.push(category);
          _firstHalfProductCount += categoryProductCount + 1;
          _secondHalfProductCount -= categoryProductCount + 1;
        } else {
          _secondHalfCategories.push(category);
        }
      }

      const _categoryOtherNames = categories.reduce((acc, category) => {
        const otherName = PRODUCT_CATEGORIES_ON_PDF.find((cat) => cat.id === category).otherName;
        acc[category] = otherName;
        return acc;
      }, {});
      setCategoryOtherNames(_categoryOtherNames);

      setFirstHalfCategories(_firstHalfCategories);
      setSecondHalfCategories(_secondHalfCategories);
    }
  }, [productList, tier]);

  useEffect(() => {
    if (!editMode) {
      handleDownload();
      setEditMode(true);
    }
  }, [editMode]);
  
  const loadFonts = async () => {
    try {
      // Assign the fonts to pdfMake
      pdfMake.vfs = vfs_fonts.vfs;
      // Configure pdfMake to use the fonts
      pdfMake.fonts = {
        NotoSansSC: {
          normal: 'NotoSansSC-Regular.ttf',
          bold: 'NotoSansSC-Bold.ttf',
          medium: 'NotoSansSC-Medium.ttf',
        }
      };
      
      // Log the font configuration to verify
      console.log('Font configuration:', pdfMake.fonts);
  
      console.log('Fonts loaded successfully');
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };

  const handleDownload = async () => {
    await loadFonts();
  
    const headerElement = document.getElementById("header");
  
    const todayStr = getPSTStartOfDay().toLocaleDateString().replaceAll("/", "-");
  
    // Convert header to canvas
    const headerCanvas = await html2canvas(headerElement, { scale: 1 });
    const headerImage = headerCanvas.toDataURL('image/jpeg');
  
    // Define page margins
    const pageMargins = [10, 60, 10, 40]; // Adjust the top margin to account for the header
  
    // Calculate header image dimensions
    const { headerImageWidth, headerImageHeight } = calculateHeaderImageDimensions(headerCanvas, pageMargins);
  
    // Calculate column width
    const pageWidth = 595.28; // A4 width in points (8.27 inches * 72 points per inch)
    const columnGap = pageMargins[0]/2; // Gap equal to left margin
    const columnWidth = (pageWidth - pageMargins[0] - pageMargins[2] - columnGap) / 2;
  
    // Generate tables for the left column
    const leftColumnTables = firstHalfCategories.map((category, index) => {
      const tableBody = [
        [{ text: 'QTY', style: 'tableHeader' }, 
         { text: String(clonedGroupedProducts[category][0].category.name).toUpperCase(), style: 'tableHeader' }, 
         { text: String(categoryOtherNames[category]).toUpperCase(), style: 'tableHeader' }, 
         { text:'PRICE', style: 'tableHeader' }, 
         { text: 'LB', style: 'tableHeader' }, 
         { text: 'ORI', style: 'tableHeader' }] 
      ];
  
      clonedGroupedProducts[category].forEach((product, idx) => {
        tableBody.push([' ', product.name ? product.name : ' ', { text: product.otherName ? product.otherName : ' ', style: 'smallFont' } , { text: product.price ? `${product.price}` : ' ', style: 'priceFont' } , { text: product.weight ? product.weight : ' ', style: 'weightFont'} , {text: product.origin ? product.origin.substring(0, 3).toUpperCase() : ' ', style: 'normalFont'}]);
      });
  
      return {
        table: {
          headerRows: 1,
          widths: ['10%', '39%', '16%', '11%', '14%', '10%'],
          body: tableBody,
          layout: {
            hLineWidth: function(i, node) {
              return (i === 0 || i === node.table.body.length) ? 1 : 0;
            },
            vLineWidth: function() {
              return 0;
            },
            hLineColor: function() {
              return 'black';
            }
          }
        },
        margin: [0, 0, 0, 10] // Margin between tables
      };
    });
  
    // Generate tables for the right column
    const rightColumnTables = secondHalfCategories.map((category, index) => {
      const tableBody = [
        [{ text: 'QTY', style: 'tableHeader' }, 
         { text: String(clonedGroupedProducts[category][0].category.name).toUpperCase(), style: 'tableHeader' }, 
         { text: String(categoryOtherNames[category]).toUpperCase(), style: 'tableHeader' }, 
         { text:'PRICE', style: 'tableHeader' }, 
         { text: 'LB', style: 'tableHeader' }, 
         { text: 'ORI', style: 'tableHeader' }] 
      ];
  
      clonedGroupedProducts[category].forEach((product, idx) => {
        tableBody.push([' ', product.name ? product.name : ' ', { text: product.otherName ? product.otherName : ' ', style: 'smallFont' } , { text: product.price ? `${product.price}` : ' ', style: 'priceFont' } , { text: product.weight ? product.weight : ' ', style: 'weightFont'} , {text: product.origin ? product.origin.substring(0, 3).toUpperCase() : ' ', style: 'normalFont'}]);
      });
  
      return {
        table: {
          headerRows: 1,
          widths: ['10%', '39%', '16%', '11%', '14%', '10%'],
          body: tableBody,
          layout: {
            hLineWidth: function(i, node) {
              return (i === 0 || i === node.table.body.length) ? 1 : 0;
            },
            vLineWidth: function() {
              return 0;
            },
            hLineColor: function() {
              return 'black';
            }
          }
        },
        margin: [0, 0, 0, 10] // Margin between tables
      };
    });
  
    // Define the document
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [10, headerImageHeight + 20, 5, 50],
      defaultStyle: {
        font: 'NotoSansSC',
        fontSize: 8, // Adjust the font size
        lineHeight: 0.9
      },
      header: function(currentPage, pageCount) {
        return {
          image: headerImage,
          width: headerImageWidth,
          height: headerImageHeight,
          alignment: 'center',
          margin: [10, 10, 10, 10] // Margin around the header image
        };
      },
      content: [
        {
          columns: [
            {
              width: columnWidth,
              stack: leftColumnTables
            },
            {
              width: columnWidth,
              stack: rightColumnTables
            }
          ],
          columnGap: columnGap, // Gap between columns equal to left margin
        }
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 6,
          color: 'red',
          fillColor: '#f2f2f2',
          alignment: 'center',
        },
        smallFont: {
          fontSize: 6,
        },
        priceFont: {
          fontSize: 9,
          alignment: 'center',
          bold: true
        }, 
        normalFont: {
          fontSize: 8,
          alignment: 'center'
        },
        weightFont: {
          fontSize: 7,
          alignment: 'center'
        }
      },
      footer: function(currentPage, pageCount) {
        return {
          text: `Page ${currentPage} of ${pageCount}`,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        };
      }
    };
  
    // Generate the PDF
    pdfMake.createPdf(docDefinition).download(`customerPriceList_${todayStr}.pdf`);
  };

   // Function to calculate header image dimensions
   function calculateHeaderImageDimensions(headerCanvas, pageMargins) {
    const pageWidth = 595.28; // A4 width in points (8.27 inches * 72 points per inch)
    const totalMargins = pageMargins[0] + pageMargins[2]; // left + right margins
    const headerImageWidth = pageWidth - totalMargins;
    const headerImageHeight = (headerCanvas.height / headerCanvas.width) * headerImageWidth;
    return { headerImageWidth, headerImageHeight };
  }

  /*
  const handleDownload = async () => {
    const input = document.getElementById("price_list");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const margin = 10; // Set your desired margin

      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = imgWidth / pdfWidth;
      const imgHeightInPdf = imgHeight / ratio;

      let heightLeft = imgHeightInPdf;
      let position = margin;

      pdf.addImage(imgData, "PNG", margin, position, pdfWidth, imgHeightInPdf);
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeightInPdf;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", margin, position, pdfWidth, imgHeightInPdf);
        heightLeft -= pdfHeight;
      }

      pdf.save(`customerPriceList_${todayStr}.pdf`);
    });
  };
  */
/*
  const handleDownload = async () => {
    const headerElement = document.getElementById("header");
    const leftTableElement = document.getElementById("left-table");
    const rightTableElement = document.getElementById("right-table");

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Convert header to canvas
    const headerCanvas = await html2canvas(headerElement, { scale: 1 });
    const headerImage = headerCanvas.toDataURL('image/jpeg');

    // Add header to the first page
    pdf.addImage(headerImage, 'JPEG', 10, 10, pageWidth - 20, (headerCanvas.height / headerCanvas.width) * (pageWidth - 20));

    // Extract table data
    const leftTableRows = Array.from(leftTableElement.querySelectorAll("tbody tr")).map(row => Array.from(row.querySelectorAll("td")).map(cell => cell.innerText));
    const rightTableRows = Array.from(rightTableElement.querySelectorAll("tbody tr")).map(row => Array.from(row.querySelectorAll("td")).map(cell => cell.innerText));

    // Ensure left table rows are padded with empty strings if shorter
    const leftTableHeadersLength = leftTableElement.querySelectorAll("thead th").length;
    const tableRows = rightTableRows.map((rightRow, index) => {
      const leftRow = leftTableRows[index] || Array(leftTableHeadersLength).fill('');
      return [...leftRow, ...rightRow];
    });

    // Extract table headers
    const leftTableHeaders = Array.from(leftTableElement.querySelectorAll("thead th")).map(th => th.innerText);
    const rightTableHeaders = Array.from(rightTableElement.querySelectorAll("thead th")).map(th => th.innerText);
    const tableHeaders = [...leftTableHeaders, ...rightTableHeaders];

    // Add table to PDF using jsPDF-AutoTable
    pdf.autoTable({
      head: [tableHeaders],
      body: tableRows,
      startY: 50, // Start position after header
      margin: { top: 10, bottom: 10 },
      theme: 'grid',
      headStyles: { fillColor: [255, 0, 0] },
      didDrawPage: (data) => {
        // Add header to each page
        pdf.addImage(headerImage, 'JPEG', 10, 10, pageWidth - 20, (headerCanvas.height / headerCanvas.width) * (pageWidth - 20));
        // Add footer with page numbers
        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setPage(pageCount);
        pdf.text(`Page ${pageCount} of ${pageCount}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      }
    });

    // Finalize
    pdf.save(`customerPriceList_${todayStr}.pdf`);
  };
*/

  const handlePriceChange = (category, index, value) => {
    const clonedGroupedProductsCopy = JSON.parse(JSON.stringify(clonedGroupedProducts));
    clonedGroupedProductsCopy[category][index].price = value;
    setClonedGroupedProducts(clonedGroupedProductsCopy);
  };

  const handleTierSelect = (e) => {
    setTier(e.target.value);
  };

  const handleDateRangeChange = (value) => {
    setDateRangeStr(value);
  };

  if (loadingAdmin || isLoadingProducts || !user) return <Loader />;

  const salesReps = [
    {name: "Jack", email: "jack@tomproduce.com", phone: "(626) 807-6625"},
    {name: "Anita", email: "anita@tomproduce.com", phone: "(626) 638-5645"},
    {name: "Kevin", email: "tomtradinginc@gmail.com", phone: "(626) 478-6248"},
  ];

  const saleString1 = `Jack jack@tomproduce.com (626) 807-6625 , Lang lang@tomproduce.com (562) 713-5622`
  const saleString2 = `Kevin tomtradinginc@gmail.com (626) 478-6248`;
  return (
    <>
    <MetaData title={"Price List for Customer"} />
    <HeaderAdmin title={"Price List for Customer"} />
    <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRICE_LIST.name}>
      <div className="my-5 col-11">
        <div className="row col-12">
          <div className="col-2">
            <select className="w-100" style={{ height: '40px' }} value={tier} onChange={(e)=> {handleTierSelect(e)}}>
              <option value={1}>Tier 1</option>
              <option value={2}>Tier 2</option>
              <option value={3}>Tier 3</option>
            </select>
          </div>
          <div className="col-3 offset-7">
            <button className="btn btn-success text-end" onClick={() => {setEditMode(false)}}>
              <i className="fa fa-print"></i>&nbsp;Download Price List
            </button>
          </div>
        </div>
        <div id="price_list" className="p-3 border border-secondary" style={{ marginTop: '40px' }}>
          <div id="header" className="text-center">
            <div className='row col-12 text-center'>
              <div className='col-3'>
                <img src="/images/TomProduce_logo.jpg" alt="Company Logo" style={{ width: '100%', maxWidth: '150px' }} />
              </div>
              <div className='col-6 text-center'>
                <h1>{COMPANY_NAME}</h1>
                <p style={{ fontSize: '17px', margin: '0 0 5px 0'  }}>{COMPANY_ADDRESS_LINE1},{COMPANY_ADDRESS_LINE2}</p>
                <p style={{ fontSize: '17px', margin: '0' }}>Phone: {COMPANY_ADDRESS_PHONE} - Fax: {COMPANY_ADDRESS_FAX}</p>
              </div>
              <div className='col-3'>
                <strong style={{ fontSize: '16px' }}>Date</strong>
                {editMode ? (<input
                              type='text'
                              value={dateRangeStr}
                              onChange={(e) => {handleDateRangeChange(e.target.value)}}
                              style={{ textAlign: "center", width: "100%", padding: '15px', fontSize: '14px' }}
                            />
                          )           
                          : dateRangeStr}
              </div>
            </div>
            <div>
              <strong style={{ fontSize: '16px' }}>SALES:&nbsp;</strong>
              {saleString1}
            </div>
            <div>
              {saleString2}
            </div>
          </div>
          <div id="table" className="row">
            <div className="col-6">
              <div className="table-responsive">
                <table id='left-table' style={{ border: '2px solid black', borderCollapse: 'collapse', width: '100%' }}>
                  <tbody>
                    {firstHalfCategories.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none'}}>{index === 0 ? 'QTY' : ''}</th>
                          <th style={{ width:'40%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(clonedGroupedProducts[category][0].category.name).toUpperCase()}</th>
                          <th style={{ width:'20%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(categoryOtherNames[category]).toUpperCase()}</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>PRICE</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>LB</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>ORI</th>
                        </tr>
                        {clonedGroupedProducts[category].map((product, idx) => (
                          <tr key={idx}>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}></td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{product.name}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} className='text-center'>{product.otherName}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{editMode ? <EditableNumberCell value={`${product.price}`} type={'text'} onChange={(val) => {handlePriceChange(category, idx, val)}} ></EditableNumberCell> : (product.price)}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.weight}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.origin}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6">
              <div className="table-responsive">
                <table id='right-table' style={{ border: '2px solid black', borderCollapse: 'collapse', width: '100%' }}>
                  <tbody>
                    {secondHalfCategories.map((category, index) => (
                      <React.Fragment key={index}>
                      <tr>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none'}}>{index === 0 ? 'QTY' : ''}</th>
                        <th style={{ width:'40%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(clonedGroupedProducts[category][0].category.name).toUpperCase()}</th>
                        <th style={{ width:'20%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(categoryOtherNames[category]).toUpperCase()}</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>PRICE</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>LB</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>ORI</th>
                      </tr>
                      {clonedGroupedProducts[category].map((product, idx) => (
                        <tr key={idx}>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}></td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{product.name}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} className='text-center'>{product.otherName}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{editMode ? <EditableNumberCell value={`$${product.price}`} type={'text'} onChange={(val) => {handlePriceChange(category, idx, val)}} ></EditableNumberCell> : (product.price)}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.weight}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.origin}</td>
                      </tr>
                      ))}
                    </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
    </>
  );
};

export default CustomerPriceList;
