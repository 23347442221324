import Select from 'react-select';

export const CustomerSelection = ({ customers, onChange, selectedCustomerId }) => {
    const options = customers?.map(customer => ({
        value: customer,
        label: customer.name,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: 'green',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 5 }),
    };

    const selectedOption = options.find(option => option.value._id === selectedCustomerId);

    return (
        <div>
            <Select 
                value={selectedOption}
                onChange={(selected) => {onChange(selected.value)}}
                options={options} 
                isSearchable
                styles={customStyles}
                menuPortalTarget={document.body} // Add this line
                menuPosition={'fixed'} // Add this line
                maxMenuHeight={'50vh'}
            /> 
        </div>
    );
};