
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_MENU_ITEMS, USER_ROLES } from "../../constants/constants.js";

import { setAdminOptions } from "../../redux/features/adminOptionsSlice";

const SideMenu = ({ menuItems, activeMenu }) => {
  const adminOptions = useSelector((state) => state.adminOptions);

  const {user, unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase} = useSelector((state) => state.adminAuth);

  const [newIndicator, setNewIndicator] = useState({});
  const [updateIndicator, setUpdateIndicator] = useState({});

  const dispatch = useDispatch();
  
  useEffect(() => {
    const totalNewOrder = Object.values(unviewedNewOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalNewPurchase = Object.values(unviewedNewPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setNewIndicator(
      {
        ...newIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalNewOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalNewPurchase,
      }
    )

    const totalUpdatedOrder = Object.values(unviewedUpdatedOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalUpdatedPurchase = Object.values(unviewedUpdatedPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setUpdateIndicator(
      {
        ...updateIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalUpdatedOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalUpdatedPurchase,
      }
    )
  }, [unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase]);

  //console.log("newIndicator", menuItems);

  const handleClick = (event, isDisabled) => {
    if (isDisabled) {
      event.preventDefault();
      return;
    }

    if (activeMenu === 'Orders') {
      const newAdminOptions = {...adminOptions, allOrdersDateFilter: null, allOrdersStatusFilter: null};
      dispatch(setAdminOptions(newAdminOptions)); 
    } else if (activeMenu === 'Purchases') {
      const newAdminOptions = {...adminOptions, allPurchasesDateFilter: null, allPurchasesStatusFilter: null};
      dispatch(setAdminOptions(newAdminOptions));
    }
  };

  return (
    <div className="list-group mt-5 pl-4">
      
      {menuItems?.map((menuItem, index) => {
        const isDisabled = (!menuItem.roles || !user || !menuItem.roles.includes(user.role)) && !menuItem.roles.includes(USER_ROLES.ALL);
        return(
          <Link
            key={index}
            to={isDisabled ? "#" : menuItem.link}
            className={`fw-bold list-group-item list-group-item-action ${
              menuItem.name === activeMenu ? "active" : ""
            } ${isDisabled ? "disabled-text" : ""}`}
            aria-current={menuItem.name === activeMenu ? "true" : "false"}
            tabIndex={isDisabled ? -1 : 0}
            onClick={(event) => handleClick(event, isDisabled)}
          >
            <div className="menu-item d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i className={`${menuItem.icon} fa-fw pe-2`} style={{marginRight: '4px'}}></i> {menuItem.name}
              </div>
              <div className="d-flex align-items-center">
                {newIndicator && newIndicator[menuItem.id] !== null && newIndicator[menuItem.id] !== undefined && newIndicator[menuItem.id] > 0 && (
                  <span className="new-items-indicator">{`${newIndicator[menuItem.id]} New`}</span>
                )}
                
                {updateIndicator && updateIndicator[menuItem.id] !== null && updateIndicator[menuItem.id] !== undefined && updateIndicator[menuItem.id] > 0 && (
                  <span className="updated-items-indicator">{`${updateIndicator[menuItem.id]} Updates`}</span>
                )}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default SideMenu;
