import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData.jsx";
import HeaderAdmin from "../../layout/HeaderAdmin.jsx";
import AdminLayout from "../../layout/AdminLayout.jsx";
import {
  useAdminGetQuickbooksLogQuery,
} from "../../../redux/api/adminApi.js";
import { ADMIN_MENU_ITEMS } from "../../../constants/constants.js";


const ListQuickbooksLog = () => {
  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  let [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const ref = searchParams.get("ref");

  const { data, isLoading } = useAdminGetQuickbooksLogQuery({category, ref});

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (data) {
      setLogs(data.logs);
    }
  }, [data]);
  
  if (isLoading || loadingAdmin) return <Loader />;

  return (
    <>
    <MetaData title={"Quickbooks Logs"} />
    <HeaderAdmin title={`Quickbooks Logs`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.TOOLS.name}>
        <div className="col-11 col-lg-11">
          {logs.map((log, index) => (
            <div key={index} style={{ whiteSpace: 'pre-wrap', marginBottom: '10px' }}>
              {`${new Date(log.createdAt).toLocaleString()}  - ${log.type ? log.type.toUpperCase() : ''} - ${log.ref} - ${log.status} - ${log.note ? log.note : ''}`}
            </div>
          ))}
        </div>
      </AdminLayout>
    </>
  );
};

export default ListQuickbooksLog;