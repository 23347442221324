import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SalesChart from "../charts/SalesChart";
import { useLazyAdminGetDashboardSalesQuery } from "../../redux/api/orderAdminApi";
import { useLazyAdminGetDashboardPurchasesQuery } from "../../redux/api/purchaseAdminApi";
import { toast } from "react-hot-toast";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import { set } from "lodash";
import HeaderAdmin from "../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS } from "../../constants/constants.js";
import { getDateEndTimeInPST, getDateStartTimeInPST, getPSTStartOfDay } from "../../utils/utilities";
import { compare } from "bcryptjs";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(getPSTStartOfDay().setDate(1));
  const [endDate, setEndDate] = useState(getPSTStartOfDay());
  const [dataType, setDataType] = useState("Sales");

  const [dataQB, setDataQB] = useState([]);
  const [totalSalesQB, setTotalSalesQB] = useState(0);
  const [totalNumOrdersQB, setTotalNumOrdersQB] = useState(0);


  const [dataNoQB, setDataNoQB] = useState([]);
  const [totalSalesNoQB, setTotalSalesNoQB] = useState(0);
  const [totalNumOrdersNoQB, setTotalNumOrdersNoQB] = useState(0);

  const [adminGetDashboardSales, { error: errorLoadingSales, isLoading: isLoadingSales, data: salesData }] = useLazyAdminGetDashboardSalesQuery();
  const [adminGetDashboardPurchase, { error: errorLoadingPurchases, isLoading: isLoadingPurchases, data: purchaseData }] = useLazyAdminGetDashboardPurchasesQuery();

  useEffect(() => {
    if (errorLoadingSales) {
      toast.error(errorLoadingSales?.data?.message);
    }
    if (errorLoadingPurchases) {
      toast.error(errorLoadingPurchases?.data?.message);
    }
  }, [errorLoadingSales, errorLoadingPurchases]);

  useEffect(() => {
    if (dataType === "Sales") {
      assignSalesData();
    }
  }, [salesData, dataType]);

  useEffect(() => {
    if (dataType === "Purchases") {
      assignPurchaseData();
    }
  }, [purchaseData, dataType]);

  function assignSalesData() {
    // Quickbooks data
    if (salesData) {
      const sortedSalesDataQB = salesData.QBData.sales ? salesData.QBData.sales.slice().sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      }) : [];
      setDataQB(sortedSalesDataQB);
      setTotalSalesQB(salesData.QBData.totalSales);
      setTotalNumOrdersQB(salesData.QBData.numOrders);

      // No QB data
      const sortedSalesDataNoQB = salesData.NoQBData.sales ? salesData.NoQBData.sales.slice().sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      }) : [];
      setDataNoQB(sortedSalesDataNoQB);
      setTotalSalesNoQB(salesData.NoQBData.totalSales);
      setTotalNumOrdersNoQB(salesData.NoQBData.numOrders);
    } else {
      setDataQB([]);
      setTotalSalesQB(0);
      setTotalNumOrdersQB(0);

      setDataNoQB([]);
      setTotalSalesNoQB(0);
      setTotalNumOrdersNoQB(0);
    }
  }

  function assignPurchaseData() {
    // Quickbooks purchaseData
    if (purchaseData) {
      const sortedPurchaseDataQB = purchaseData.QBData.sales ? purchaseData.QBData.sales.slice().sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      }) : [];
      setDataQB(sortedPurchaseDataQB);
      setTotalSalesQB(purchaseData.QBData.totalSales);
      setTotalNumOrdersQB(purchaseData.QBData.numOrders);

      // No QB data
      const sortedPurchaseDataNoQB = purchaseData.NoQBData.sales ? purchaseData.NoQBData.sales.slice().sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      }) : [];
      setDataNoQB(sortedPurchaseDataNoQB);
      setTotalSalesNoQB(purchaseData.NoQBData.totalSales);
      setTotalNumOrdersNoQB(purchaseData.NoQBData.numOrders);
    } else {
      setDataQB([]);
      setTotalSalesQB(0);
      setTotalNumOrdersQB(0);

      setDataNoQB([]);
      setTotalSalesNoQB(0);
      setTotalNumOrdersNoQB(0);
    }
  }

  const handleTypeChange = (e) => {
    setDataType(e.target.value);
  };

  const submitHandler = () => {
    const _startDate = getDateStartTimeInPST(startDate);
    const _endDate = getDateEndTimeInPST(endDate);

    //console.log("startDate", startDate, _startDate, endDate, _endDate);

    if (dataType === "Sales") {
      adminGetDashboardSales({
        startDate: _startDate,
        endDate: _endDate,
      });
    } else {
      adminGetDashboardPurchase({
        startDate: _startDate,
        endDate: _endDate,
      });
    }
  };

  const formatMoney = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <>
      <MetaData title={"Dashboard"} />
      <HeaderAdmin title={"Dashboard"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DASHBOARD.name}>
        <div className="d-flex justify-content-start align-items-center">
        <div className="mb-3 me-4">
            <label className="form-label d-block">Select Type</label>
            <select value={dataType} className="form-select" onChange={handleTypeChange}>
              <option value="Sales">Sales</option>
              <option value="Purchases">Purchases</option>
            </select>
          </div>
          <div className="mb-3 me-4 offset-5">
            <label className="form-label d-block">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-block">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
            />
          </div>
          <button
            className="btn fetch-btn ms-4 mt-3 px-5"
            onClick={submitHandler}
          >
            Fetch
          </button>
        </div>

        {/* Loader */}
        {(isLoadingSales || isLoadingPurchases) && (
          <div style={{position: 'relative', height: '100%', width: '100%'}}>
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
              <Loader />
            </div>
          </div>
        )}

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {/* Table with QB*/}
              <h5 className="card-title">{ dataType === 'Sales' ? 'Sales Data - QB' : 'Purchase Data - QB' }</h5>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{ dataType === 'Sales' ? 'Customer' : 'Vendor' }</th>
                      <th>{ dataType === 'Sales' ? 'Order Count' : 'Purchase Count' }</th>
                      <th>{ dataType === 'Sales' ? 'Total Sale' : 'Total Purchase' }</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataQB.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td style={{ textAlign: 'right' }}>{dataType === 'Sales' ? item.numOrders : item.numPurchases}</td>
                        <td style={{ textAlign: 'right' }}>{dataType === 'Sales' ? formatMoney(item.totalSales) : formatMoney(item.totalPurchase)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td><b></b></td>
                      <td><b>Total</b></td>
                      <td style={{ textAlign: 'right' }}><b>{totalNumOrdersQB}</b></td>
                      <td style={{ textAlign: 'right' }}><b>{formatMoney(totalSalesQB)}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table with QB*/}
              <h5 className="card-title">{ dataType === 'Sales' ? 'Sales Data - No QB' : 'Purchase Data - No QB' }</h5>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{ dataType === 'Sales' ? 'Customer' : 'Vendor' }</th>
                      <th>{ dataType === 'Sales' ? 'Order Count' : 'Purchase Count' }</th>
                      <th>{ dataType === 'Sales' ? 'Total Sale' : 'Total Purchase' }</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataNoQB.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td style={{ textAlign: 'right' }}>{dataType === 'Sales' ? item.numOrders : item.numPurchases}</td>
                        <td style={{ textAlign: 'right' }}>{dataType === 'Sales' ? formatMoney(item.totalSales) : formatMoney(item.totalPurchase)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td><b></b></td>
                      <td><b>Total</b></td>
                      <td style={{ textAlign: 'right' }}><b>{totalNumOrdersNoQB}</b></td>
                      <td style={{ textAlign: 'right' }}><b>{formatMoney(totalSalesNoQB)}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5"></div>
      </AdminLayout>
    </>
  );
};

export default Dashboard;
