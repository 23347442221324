import React,  { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
//import { PDFDocument } from 'pdf-lib';

import { useLazyAdminPurchaseDetailsQuery } from "../../../redux/api/purchaseAdminApi";
import Loader from "../../layout/Loader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import PrintPurchaseInvoice from "../../printings/PurchaseInvoice";
import HeaderAdmin from "../../layout/HeaderAdmin";
import MetaData from "../../layout/MetaData";

const AdminPurchaseInvoice = () => {
    const params = useParams();
    const purchaseId = params?.id;
    const isOldPurchase = window.location.href.includes('oldinvoice');

    const [ adminPurchaseDetail, { data: purchaseDetails, error: loadingPurchaseDetailError }]  = useLazyAdminPurchaseDetailsQuery();

    useEffect(() => {
      adminPurchaseDetail({ id: purchaseId, isOldPurchase});
    }, []);

    useEffect(() => {
        if (loadingPurchaseDetailError) {
          toast.error(loadingPurchaseDetailError?.data?.message);
        } 
    }, [loadingPurchaseDetailError]);

    return (
        <>
          <MetaData title={"Purchase Invoice"} />
          <HeaderAdmin />

          {(!purchaseDetails) && (<Loader/>)}
          {purchaseDetails && (
            <div style={{width: '95vw', display: 'flex', justifyContent: 'center'}}>
              <PrintPurchaseInvoice purchase={purchaseDetails} />
            </div>
          )}
        </>
      );
};

export default AdminPurchaseInvoice;