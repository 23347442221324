
import React, { useState, useEffect, useRef  } from 'react';
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import _ from "lodash";
import Loader from '../Loader';
import { EditableNumberCell } from '../CustomMDBDataTableCell';

export const InventoryUpdatePopup = ({ onClose, product, title, data, visibility, updateFunction }) => {
  const [clonedOrdersData, setClonedOrdersData] = useState({});
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [dataTimestamp, setDataTimestamp] = useState(0);
  const [returnAmount, setReturnAmount] = useState(0);
  const [adjustmentType, setAdjustmentType] = useState('manual');
  const [orderNumber, setOrderNumber] = useState('');
  const [notes, setNotes] = useState('');

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const popupRef = useRef(); // Create a ref

  useEffect(() => {
    if (updateFunction?.updating === false) {
      if (data) {
        if (dataTimestamp !== data?.updatedAt) {
          setDataTimestamp(data?.updatedAt);
          setClonedOrdersData(JSON.parse(JSON.stringify(data)));
        }
      } else {
        setClonedOrdersData({});
      }
      setThereAreValueChanges(false);
    }
  }, [data]);

  // Add this useEffect hook
  useEffect(() => {
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // don't allow click below the popup
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("mouseup", handleClick);
    } else {
      document.removeEventListener("mouseup", handleClick);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClick);
    };
  }, [visibility]);

  useEffect(() => {
    checkIfThereAreChanges();
  }, [adjustmentType, clonedOrdersData, returnAmount]);
  
  //--------------------------------- Functions ---------------------------------
  // Update quantity of the orders
  const handleStockChange = (value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrdersData.quantity !== value) {
      const newData = {...clonedOrdersData, quantity: Number(value)};
      setClonedOrdersData(newData);
    }
  };

  const handleReturnAmountChange = (value) => {
    if (valueIsANonNegativeNumber(value) && returnAmount !== value) {
      setReturnAmount(Number(value));
    }
  }

  const handleOrderNumberChange = (value) => {
    if (orderNumber !== value) {
      setOrderNumber(value);
    }
  };

  const handleAjustmentTypeChange = (value) => {
    if (adjustmentType !== value) {
      setAdjustmentType(value);
    }
  };

  const checkIfThereAreChanges = () => {
    if (adjustmentType === 'manual') {
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
    } else if (adjustmentType === 'refund') {
      setThereAreValueChanges(returnAmount !== 0);
    }
  }

  // Update inventory
  const updateHandler = () => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      if (adjustmentType === 'refund') {
        updateFunction?.update({ id: product._id, body: { adjustmentType, returnAmount, orderNumber, notes } });
      } else if (adjustmentType === 'manual') {
        updateFunction?.update({ id: product._id, body: { adjustmentType, newStock: clonedOrdersData.quantity, notes } });
      }
    }
  };

  const popupStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };

  return(
    <div ref={popupRef} id="popup" class="popupSmall" style={{...popupStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div className="col-2"> 
        </div> 
        <div className="col-8 text-center"><h5>{title}</h5></div>
        <div className="col-2 text-end">
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div style={{ width: '96%', maxHeight: '80vh', overflow: 'overlay'}}>
        <table className="table table-bordered" style={{ width: '100%' }}>
          <tbody>
            <tr style={{width: '100%'}}>
              {/* reason */}
              <td className={'text-start'} style={{width: '50%'}}>
                Reason:
              </td>
              <td className={'text-end'} style={{width: '50%'}}>
                <select
                  value={adjustmentType}
                  onChange={(e) => handleAjustmentTypeChange(e.target.value)}
                  style={{ width: '100%', padding: '5px' }}
                >
                  <option value="manual">Adjustment</option>
                </select>
              </td>
            </tr>
            {adjustmentType === 'manual' && (
              <tr style={{width: '100%'}}>
                {/* stock */}
                <td className={'text-start'} style={{width: '50%'}}>
                  Stock
                </td>
                <td className={'text-end'} style={{width: '50%'}}>
                  <EditableNumberCell 
                    value={clonedOrdersData.quantity} onChange={(val) => {handleStockChange(val);}} color={clonedOrdersData?.quantity !== data?.quantity ? 'red' : 'black'}> 
                  </EditableNumberCell>
                </td>
              </tr>)
            }
            {adjustmentType === 'refund' && (
              <tr style={{width: '100%'}}>
                {/* stock */}
                <td className={'text-start'} style={{width: '50%'}}>
                  {adjustmentType === 'manual' ? 'Stock' : 'Amount' }:
                </td>
                <td className={'text-end'} style={{width: '50%'}}>
                  <EditableNumberCell 
                    value={returnAmount} onChange={(val) => {handleReturnAmountChange(val)}} color={clonedOrdersData?.quantity !== data?.quantity ? 'red' : 'black'}> 
                  </EditableNumberCell>
                </td>
              </tr>)
            }
            {adjustmentType === 'refund' && (
              <tr style={{width: '100%'}}>
                {/* Order number */}
                <td className={'text-start'} style={{width: '50%'}}>
                  Order #
                </td>
                <td className={'text-end'} style={{width: '50%'}}>
                  <EditableNumberCell 
                    type = 'text' value={orderNumber} onChange={(val) => {handleOrderNumberChange(val)}} color={'red'}> 
                  </EditableNumberCell>
                </td>
              </tr>)
            }
          </tbody>
        </table>
      </div>
      <div style={{ width: '96%', marginTop: '10px' }}>
        <label htmlFor="notes" style={{ display: 'block', marginBottom: '5px' }}>Notes:</label>
        <textarea
          id="notes"
          value={notes}
          onChange={handleNotesChange}
          style={{ width: '100%', height: '150px' }}
        />
      </div>
      {updateFunction?.updating && ( 
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>)
      }
      {updateFunction && (
        <button onClick={updateHandler} className="btn btn-success" disabled={!thereAreValueChanges || updateFunction?.updating}>
          Update
        </button>
      )}
    </div>
  );
};