import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { ProtectedAdminRoute } from "../auth/ProtectedAdminRoute";
import AdminLogin from "../auth/AdminLogin";
import AdminForgotPassword from "../auth/AdminForgotPassword";
import AdminResetPassword from "../auth/AdminResetPassword";
import { USER_ROLES } from "../../constants/constants.js";

import AdminProfile from "../admin/AdminProfile/AdminProfile";
import UpdateAdminProfile from "../admin/AdminProfile/UpdateAdminProfile";
import UploadAdminAvatar from "../admin/AdminProfile/UploadAdminAvatar";
import UpdateAdminPassword from "../admin/AdminProfile/UpdateAdminPassword";

import ListProducts from "../admin/Products/ListProducts";
import NewProduct from "../admin/Products/NewProduct";
import UpdateProduct from "../admin/Products/UpdateProduct";

import ListOrders from "../admin/Orders/ListOrders";
import ProcessOrder from "../admin/Orders/ProcessOrder";
import CreateOrder from "../admin/Orders/CreateOrder";
import CreateSellCreditMemo from "../admin/Orders/CreateSellCreditMemo";
import AdminInvoice from "../admin/Orders/AdminInvoice";
import AdminPackingSlip from "../admin/Orders/AdminPackingSlip";
import AdminEmailOrderToCustomer from "../admin/Orders/AdminEmailOrderToCustomer";

import ListCustomers from "../admin/Customers/ListCustomers";
import CreateCustomer from "../admin/Customers/CreateCustomer";
import UpdateCustomer from "../admin/Customers/UpdateCustomer";

import ListUsers from "../admin/Users/ListUsers";
import UpdateUser from "../admin/Users/UpdateUser";
import CreateUser from "../admin/Users/CreateUser";

import ListInventories from "../admin/Inventories/ListInventories";
import ListOrdersByProduct from "../admin/Orders/ListOrdersByProduct";

import ListVendors from "../admin/Vendors/ListVendors";
import CreateVendor from "../admin/Vendors/CreateVendor";
import UpdateVendor from "../admin/Vendors/UpdateVendor";

import ListPurchases from "../admin/Purchases/ListPurchases";
import ListPurchasesPerVendor from "../admin/Purchases/ListPurchasesPerVendor";
import ProcessPurchase from "../admin/Purchases/ProcessPurchase";
import CreatePurchase from "../admin/Purchases/CreatePurchase";

import ListDrivers from "../admin/Drivers/ListDrivers";
import UpdateDriver from "../admin/Drivers/UpdateDriver";
import CreateDriver from "../admin/Drivers/CreateDriver";
import ListDriverDeliveries from "../admin/Drivers/ListDriverDeliveries";

import PriceListPerVendor from "../admin/VendorPriceList/PriceList";
import CustomerPriceList from "../printings/CustomerPriceList.jsx";
import Dashboard from "../admin/Dashboard";
//import Tools from "../admin/Tools";
import History from "../admin/History";
import ViewUploadedDocument from "../admin/Orders/ViewUploadedDocument.jsx";

import ListQuickbooksLog from "../admin/Quickbooks/ListQuickbooksLog.jsx";
import CreateVendorCredit from "../admin/Purchases/CreateVendorCreditMemo.jsx";

import AdminPurchaseInvoice from "../admin/Purchases/AdminPurchaseInvoice.jsx";


const AdminRoutes = () => {
  return (
    <>
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/password/forgot" element={<AdminForgotPassword />} />
      <Route path="/admin/password/reset/:token" element={<AdminResetPassword />} />
      {/* ------------------------- ORDERS ----------------------------- */}
      <Route path="/admin/orders" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <ListOrders />
        </ProtectedAdminRoute>} 
      />
      
      <Route path="/admin/profile" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.DRIVER]}>
          <AdminProfile />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/update_profile" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.DRIVER]}>
          <UpdateAdminProfile />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/upload_avatar" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.DRIVER]}>
          <UploadAdminAvatar />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/update_password" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.DRIVER]}>
          <UpdateAdminPassword />
        </ProtectedAdminRoute>}
      />
      
      <Route path="/admin/orders/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ProcessOrder />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/oldorders/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ProcessOrder />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/orders/:id/invoice" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminInvoice />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/orders/:id/oldinvoice" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminInvoice />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/orders/:id/packingslip" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminPackingSlip />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/orders/:id/email" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminEmailOrderToCustomer />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/orders/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <CreateOrder />
        </ProtectedAdminRoute>}
      />   
      <Route path="/admin/sellcredits/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT]}>
          <CreateSellCreditMemo />
        </ProtectedAdminRoute>}
      />  
      <Route path="/admin/orders/viewdocument/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.ACCOUNTANT, USER_ROLES.DRIVER]}>
          <ViewUploadedDocument />
        </ProtectedAdminRoute>}
      />   

      {/* ------------------------- PURCHASES ----------------------------- */}
      <Route path="/admin/purchases" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ListPurchases />
        </ProtectedAdminRoute>} 
      />
      <Route path="/admin/purchases/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ProcessPurchase />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/purchases/:id/invoice" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminPurchaseInvoice />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/purchases/:id/oldinvoice" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <AdminPurchaseInvoice />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/oldpurchases/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ProcessPurchase />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/purchases/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <CreatePurchase />
        </ProtectedAdminRoute>}
      />   
      <Route path="/admin/vendorcredits/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT]}>
          <CreateVendorCredit  />
        </ProtectedAdminRoute>}
      />  
      <Route path="/admin/vendors/:id/purchases/" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <ListPurchasesPerVendor />
        </ProtectedAdminRoute>}
      />   

      {/* ------------------------- PRODUCTS ----------------------------- */}
      <Route path="/admin/orders/product/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ListOrdersByProduct />
        </ProtectedAdminRoute>}
      />   
      <Route path="/admin/products" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <ListProducts />
        </ProtectedAdminRoute>}
      />    
      <Route path="/admin/products/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <NewProduct />
        </ProtectedAdminRoute>}
      />    
      <Route path="/admin/products/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <UpdateProduct />
        </ProtectedAdminRoute>}
      />                 
      {/* ------------------------- CUSTOMERS ----------------------------- */}
      <Route path="/admin/customers" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT]}>
          <ListCustomers />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/customers/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <CreateCustomer />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/customers/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <UpdateCustomer />
        </ProtectedAdminRoute>}
      />
      {/* Price List*/}
      <Route path="/admin/customerpricelist" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <CustomerPriceList />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- USERS ----------------------------- */}
      <Route path="/admin/users" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN]}>
          <ListUsers />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/users/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN]}>
          <CreateUser />
        </ProtectedAdminRoute>}
      />      
      <Route path="/admin/users/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN]}>
          <UpdateUser />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- DRIVERS ----------------------------- */}
      <Route path="/admin/drivers" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
          <ListDrivers />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/drivers/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
          <CreateDriver />
        </ProtectedAdminRoute>}
      />      
      <Route path="/admin/drivers/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
          <UpdateDriver />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/drivers/:id/deliveries" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
          <ListDriverDeliveries />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- INVENTORIES ----------------------------- */}
      <Route path="/admin/inventories" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <ListInventories />
        </ProtectedAdminRoute>} 
      />
      {/* ------------------------- VENDORS ----------------------------- */}
      <Route path="/admin/vendors" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <ListVendors />
        </ProtectedAdminRoute>}
      />
      <Route path="/admin/vendors/new" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <CreateVendor />
        </ProtectedAdminRoute>}
      />      
      <Route path="/admin/vendors/:id" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <UpdateVendor />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- PRICELIST ----------------------------- */}
      <Route path="/admin/vendors/:vendorId/pricelist/" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE]}>
          <PriceListPerVendor />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- DASHBOARD ----------------------------- */}

      <Route path="/admin/history" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE]}>
          <History />
        </ProtectedAdminRoute>}
      />
      
      <Route path="/admin/dashboard" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN]}>
          <Dashboard />
        </ProtectedAdminRoute>}
      />
      {/* ------------------------- TOOLS ----------------------------- */}
      <Route path="/admin/quickbookslog" element={
        <ProtectedAdminRoute role={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
          <ListQuickbooksLog />
        </ProtectedAdminRoute>}
      />
    </>
  );
};

export default AdminRoutes;
