import { ORDER_USER_RIGHTS, ORDER_STATUS, USER_ROLES, PURCHASE_STATUS, PURCHASE_USER_RIGHTS } from '../../constants/constants.js';

//------------------- ORDER -------------------
export const DetermineUserOrderRights = (user, orderStatus, isCR) => {
    if (user) {
        if (isCR) {
            if (user.role === USER_ROLES.SUPER_ADMIN) {
                return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE]};
            }
            if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                return {rights: [ORDER_USER_RIGHTS.UPDATE]};
            }
            return {rights: []};
        }
        switch (orderStatus) {
            case ORDER_STATUS.PENDING:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.CONFIRM, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.CONFIRM, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.SALE) {
                    return {rights: [ORDER_USER_RIGHTS.CONFIRM, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
                }
                break;
            case ORDER_STATUS.CONFIRMED:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.PACK, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.SALE) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
                }
                if (user.role === USER_ROLES.ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.PACK, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [ORDER_USER_RIGHTS.PACK]};
                }
                break;
            case ORDER_STATUS.PACKING:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.FINISH_PACKING, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.FINISH_PACKING, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.FINISH_PACKING]};
                }
                break;
            case ORDER_STATUS.READY_TO_SHIP:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return { rights: [ORDER_USER_RIGHTS.SHIP, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.ADMIN) {
                    return { rights: [ORDER_USER_RIGHTS.SHIP, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.SWITCH_CUSTOMER]};
                }
                if (user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [ORDER_USER_RIGHTS.SHIP, ORDER_USER_RIGHTS.UPDATE]};
                }
                break;
            case ORDER_STATUS.SHIPPED:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELIVER, ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.VERIFY_INVOICE_SIGNATURE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.DELETE]};
                }
                if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELIVER, ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.VERIFY_INVOICE_SIGNATURE, ORDER_USER_RIGHTS.DELETE_DOCUMENT]};
                }
                if (user.role === USER_ROLES.DRIVER) {
                    return {rights: [ORDER_USER_RIGHTS.DELIVER]};
                }
                break;
            case ORDER_STATUS.DELIVERED:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.VERIFY_INVOICE_SIGNATURE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.DELETE]};
                }
                if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                    return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.VERIFY_INVOICE_SIGNATURE, ORDER_USER_RIGHTS.DELETE_DOCUMENT]};
                }
                break;
            default:
            return {rights: []};
        }
    }
    return {rights: []};
}

export const DetermineUserOrderRightsOnInventoryPage = (user, orderStatus) => {
    if (user) {
        switch (orderStatus) {
            case ORDER_STATUS.PENDING:
                if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE  || user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
                }
                break;
            case ORDER_STATUS.CONFIRMED:
                if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
                }
                break;
            default:
            return {rights: []};
        }
    }
    return {rights: []};
}

// History orders
export const DetermineUserOldOrderRights = (user, orderStatus, isCR) => {
    if (user) {
        if (isCR) {
            if (user.role === USER_ROLES.SUPER_ADMIN) {
                return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE]};
            }
            if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                return {rights: [ORDER_USER_RIGHTS.UPDATE]};
            }
            return {rights: []};
        }

        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE_DOCUMENT, ORDER_USER_RIGHTS.DELETE]};
        }
        if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
            return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE_DOCUMENT]};
        }
    }
    return {rights: []};
  }

export const CanUserUpdateInProgressOrder = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserCreateOrder = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUserViewOrder = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.ACCOUNTANT || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserCreditOrder = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT;
}
//------------------- PURCHASE -------------------
export const DetermineUserPurchaseRights = (user, purchaseStatus, isCR) => {
    if (user) {
        if (isCR) {
            if (user.role === USER_ROLES.SUPER_ADMIN) {
                return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE]};
            }
            if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                return {rights: [PURCHASE_USER_RIGHTS.UPDATE]};
            }
            return {rights: []};
        }
        switch (purchaseStatus) {
            case PURCHASE_STATUS.PENDING:
                if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE) {
                    return {rights: [PURCHASE_USER_RIGHTS.ORDER, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
                }
                break;
            case PURCHASE_STATUS.ORDERED:
                if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE) {
                    return {rights: [PURCHASE_USER_RIGHTS.CONFIRM, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
                }
                break;
            case PURCHASE_STATUS.CONFIRMED:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
                }
                if (user.role === USER_ROLES.ADMIN) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
                }
                if (user.role === USER_ROLES.SALE) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD]};
                }
                if (user.role === USER_ROLES.WAREHOUSE) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
                }
                break;
            case PURCHASE_STATUS.DELIVERED:
                if (user.role === USER_ROLES.SUPER_ADMIN) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD]};
                }
                if (user.role === USER_ROLES.WAREHOUSE || user.role === USER_ROLES.ADMIN) {
                    return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
                }
                break;
            default:
                return {rights: []};
        }
    }
    return {rights: []};
}

export const DetermineUserOldPurchaseRights = (user, purchaseStatus, isCR) => {
    if (user) {
        if (isCR) {
            if (user.role === USER_ROLES.SUPER_ADMIN) {
                return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE]};
            }
            if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
                return {rights: [PURCHASE_USER_RIGHTS.UPDATE]};
            }
            return {rights: []};
        }

        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE]};
        }
        if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
        }
    }
    return {rights: []};
}

export const CanUserUpdateInProgressPurchase = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserCreatePurchase = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserViewPurchase = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.ACCOUNTANT || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserCreditPurchase = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.ACCOUNTANT;
}
//------------------- CUSTOMER -------------------
export const CanUserCreateCustomer = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUpdateCustomer = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanDeleteCustomer = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}
//------------------- VENDOR -------------------
export const CanUserCreateVendor = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanUserUpdateVendor = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanDeleteVendor = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUserUpdatePriceList = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

//------------------- USER -------------------
export const CanCreateUser = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUpdateUser = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanDeleteUser = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}
//------------------- DRIVER -------------------
export const CanCreateDriver = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanUpdateDriver = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanDeleteDriver = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}


//------------------- INVENTORY -------------------
export const CanCreateInventory = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanDeleteInventory = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUpdateInventory = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.WAREHOUSE;
}
//------------------- PRODUCT -------------------
export const CanCreateProduct = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanUpdateProduct = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanDeleteProduct = (user) => {
    // Dont allow delete
    return user.role === USER_ROLES.SUPER_ADMIN && user.name === 'KingGeorge';   
}
