import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';   // Import createApi and fetchBaseQuery from the toolkit/query/react module
import { getBackendURI, getQueryCorsMode } from './helper.js';
import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext.js';

export const productsAdminApi = createApi({   // Create a new API using the createApi function
    reducerPath: 'productsAdminApi',   // Define a unique reducer path for the API
    baseQuery: fetchBaseQuery(
        { 
            baseUrl: getBackendURI('/api/v1/admin'),
            mode: getQueryCorsMode(),
            credentials: 'include', // Include cookies in the request
        }),   // Define the base query using the fetchBaseQuery function
    tagTypes: ["AdminProduct"],   // Define the tag types for the API
    endpoints: (builder) => ({   // Define the endpoints for the API
        // Get all products
        adminGetProducts: builder.query({
            query: (params) => ({
              url: "/products",
              params: {
                key: params?.key,
                page: params?.page,
                keyword: params?.keyword,
                category: params?.category,
                isActive: params?.isActive,
                notOnPriceList: params?.notOnPriceList,
                extraParams: params?.extraParams,
              },
            }),
            providesTags: ["AdminProduct"],
        }),
        // Get a product details
        adminGetProductDetails: builder.query({   // Define a query endpoint to fetch product details
            query: (id) => `/products/${id}`,   // Define the query function to fetch product details
            transformResponse: (result) => result.product,
            providesTags: (result, error, id) => [{ type: 'AdminProduct', id }],
        }),
        adminCreateProduct: builder.mutation({
            query(body) {
              return {
                url: "/products/new",
                method: "POST",
                body,
              };
            },
            invalidatesTags: ["AdminProduct"],
        }),
        adminUpdateProduct: builder.mutation({
            query({ id, body }) {
                return {
                    url: `/products/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["AdminProduct"],
        }),
        adminForceResyncMultipleProductQuickbooks: builder.mutation({
            query(body) {
                return {
                    url: "/products/forcequickbooksresync",
                    method: "PUT",
                    params: {
                        key: body.option,
                    },
                };
            },
        }),
        adminForceResyncOneProductQuickbooks: builder.mutation({
            query(id) {
                return {
                    url: `/products/${id}/forcequickbooksresync`,
                    method: "PUT",
                };
            },
        }),
        adminUpdateMultipleProductPrice: builder.mutation({
            query({ body }) {
                return {
                    url: `/products/updateprice`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["AdminProduct"],
        }),
        adminUploadProductImages: builder.mutation({
            query({ id, formData }) {
                return {
                    url: `/products/${id}/upload_images`,
                    method: "POST",
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
            },
            invalidatesTags: ["AdminProduct"],
        }),
        adminDeleteProductImage: builder.mutation({
            query({ id, body }) {
                return {
                    url: `/products/${id}/delete_image`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["AdminProduct"],
        }),
        adminDeleteProduct: builder.mutation({
            query(id) {
                return {
                url: `/products/${id}`,
                method: "DELETE",
                };
            },
            invalidatesTags: ["AdminProduct"],
        }),
    }),
});

export const { 
    useAdminGetProductsQuery,
    useAdminGetProductDetailsQuery,
    useAdminCreateProductMutation,
    useAdminUpdateProductMutation,
    useAdminUpdateMultipleProductPriceMutation,
    useAdminUploadProductImagesMutation,
    useAdminDeleteProductImageMutation,
    useAdminDeleteProductMutation,
    useAdminForceResyncMultipleProductQuickbooksMutation,
    useAdminForceResyncOneProductQuickbooksMutation,
} = productsAdminApi;