import { DEFAULT_PRICE_PERCENTAGE_DIFFERENCE_RATE, MAX_CUSTOMER_TIER, ORDER_STATUS, PRICE_PERCENTAGE_DIFFERENCE_PER_TIER, PRICE_THRESHOLD_STATUS, TAX_PERCENTAGE, USER_ROLES } from "../constants/constants.js";
import { toast } from 'react-toastify';
import { formatInTimeZone, getTimezoneOffset, toZonedTime } from 'date-fns-tz';
//import nodemailer from "nodemailer";

// {units, estPallet, itemTotal, tax, shipping, total}
export const CalculateOrderSummary = (cartItems, inventory) => {
    let units = 0;//  cartItems?.reduce((acc, item) => acc + item?.finalQuantity, 0);
    let itemTotal = 0; // cartItems?.reduce((acc, item) => acc + item?.finalQuantity * item.finalPrice, 0);

    let estPallet = 0;
    let thereIsItemWithoutPrice = false;

    cartItems?.forEach((item) => {
      if (item.product && item.finalQuantity) {
        units += item.finalQuantity;
        if (item.finalPrice === undefined) {
          thereIsItemWithoutPrice = true;
        } else {
          itemTotal += item.finalQuantity * item.finalPrice;
        }

        if (inventory) {
          if (estPallet !== -1 ) {
            const palletSizePerUnit = item.palletSize ? item.palletSize : (inventory && inventory[item.product] ? inventory[item.product].product.palletSize : undefined);
            if (palletSizePerUnit) {
              estPallet += parseFloat((palletSizePerUnit * item.finalQuantity).toFixed(2));
              estPallet = parseFloat(estPallet.toFixed(2));
              //console.log('estPallet', estPallet, palletSizePerUnit);
            } else {
              estPallet = -1;
            }
          }
        }
      }
    });
    if (thereIsItemWithoutPrice) {
      itemTotal = undefined;
    } else {
      itemTotal = parseFloat(itemTotal.toFixed(2));
    }
    const tax =  itemTotal ? parseFloat(((itemTotal * TAX_PERCENTAGE) / 100.0).toFixed(2)) : undefined;
    const shipping = itemTotal ? 0 : undefined;
    const total = itemTotal ? itemTotal + tax + shipping : undefined;

    return {
        units, 
        estPallet: estPallet === -1 ? undefined : estPallet, 
        itemTotal, 
        tax, 
        shipping, 
        total,
    };
}

export function getSellPrice(product, tier) {
  let price, dollars, cents;
  if (!product.price ) {
    return { price: undefined, dollars: undefined, cents: undefined };
  } else {
    if (!tier || tier < 1) {
      tier = 1;
    }
    if (tier >= MAX_CUSTOMER_TIER) {
      tier = MAX_CUSTOMER_TIER;
    }


    let finalPrice = parseFloat((product.price * (100 + (tier - 1) * PRICE_PERCENTAGE_DIFFERENCE_PER_TIER) / 100).toFixed(2));

    // Round up the prices to 50 cents if greater than 1
    if (finalPrice > 1) {
      finalPrice = Math.ceil(finalPrice * 2) / 2;
    }

    price = finalPrice;
    dollars = Math.floor(finalPrice);
    cents = ((finalPrice % 1) * 100).toFixed(0).padStart(2, '0');
  }
  //console.log('tier', tier, product.price, price, dollars, cents);

  return { price, dollars, cents };
}

// {units, estPallet, itemTotal, tax, shipping, total}
export const CalculatePurchaseSummary = (items, inventory) => {
  let units = 0;
  let itemTotal = 0;

  let estPallet = 0;

  items?.forEach((item) => {
    if (item.product && item.finalQuantity && item.finalPrice) {
      units += item.finalQuantity;
      itemTotal += item.finalQuantity * item.finalPrice;

      if (estPallet !== -1 ) {
        const palletSizePerUnit = item.palletSize ? item.palletSize : ( inventory ? inventory[item.product]?.product.palletSize : undefined);
        if (palletSizePerUnit) {
          estPallet += parseFloat((palletSizePerUnit * item.finalQuantity).toFixed(2));
        } else {
          estPallet = -1;
        }
      }
    }
  });

  itemTotal = parseFloat(itemTotal.toFixed(2));

  const tax = parseFloat(((itemTotal * TAX_PERCENTAGE) / 100.0).toFixed(2));
  const shipping = 0;
  const total = parseFloat((itemTotal + tax + shipping).toFixed(2));

  //console.log(units, itemTotal, tax, shipping, total);

  return {
      units, 
      estPallet: estPallet === -1 ? undefined : estPallet, 
      itemTotal, 
      tax, 
      shipping, 
      total,
  };
}

export const SellPriceDoubleCheck = (user, product, price) => {
  if (!product) {
    return { ok: false, message: `Product not found! Please contact admin!` };
  }
  // If saleman, dont allow price below cost
  if (user.role === USER_ROLES.SALE && ((!isNaN(product.cost) && product.cost > price) && (!isNaN(product.price) && product.price > price))) {
    return { ok: false, message: `Price cannot be below cost for: ${product.name}! Please contact admin!` };
  }
  return ({ ok: true } );
}

export const calculateSellPriceThresholds = (cost, price) => {
  if (isNaN(cost) || isNaN(price) || cost === 0) {
    return PRICE_THRESHOLD_STATUS.NORMAL;
  }
  const tagetPrice = calculateTargetPrice(cost);

  if (price < cost) {
    return PRICE_THRESHOLD_STATUS.BELOW_COST;
  } else if (price < tagetPrice) {
    return PRICE_THRESHOLD_STATUS.BELOW_TARGET;
  } else if (price > tagetPrice) {
    return PRICE_THRESHOLD_STATUS.ABOVE_TARGET;
  } else {
    return PRICE_THRESHOLD_STATUS.NORMAL;
  }
}

function roundUpToNearest50Cents(num) {
  const number = parseFloat(num); // Convert string to number
  if (number > 1) {
    return Math.ceil(number * 2) / 2;
  } else {
    return number;
  }
}

export const calculateTargetPrice = (cost) => {
  if (isNaN(cost)) {
    return undefined;
  }
  const targetPrice = parseFloat((cost * (100 + DEFAULT_PRICE_PERCENTAGE_DIFFERENCE_RATE) / 100).toFixed(2));
  return roundUpToNearest50Cents(targetPrice);
}

export const removeReduntantBusinessNameFromAddress = (businessName, address) => {
  if (!businessName) {
    return '';
  }
  if (!address) {
    return businessName;
  }
  // Remove business name from address
  if (address.includes(businessName)) {
    return address.replace(businessName, '');
  }
  return address;
}


export const AlertMessage = (data) => {
    toast("my message not shown", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          fontSize: '1.5em', // This will make the text size larger
        }
    });
}

// Generate email template to send to customer
export const generateOrderEmailTemplate = (order, action) => {
  const orderStatus = order.status;
  const orderId = order._id;  
  const orderNumber = order.orderNumber;
  const customerName = order.customer.name;
  
  if (!action) {
    switch (orderStatus) {
      case ORDER_STATUS.PENDING:
        action = "created";
        break;
      case ORDER_STATUS.CONFIRMED:
        action = "confirmed";
        break;
      case ORDER_STATUS.PROCESSING:
        action = "processed";
        break;
      case ORDER_STATUS.PACKING:
        action = "packed";
        break;
      case ORDER_STATUS.SHIPPED:
        action = "shipped";
        break;
      case ORDER_STATUS.DELIVERED:
        action = "delivered";
        break;
      default:
        action = "updated";
    } 
  }

  let orderUrl;
  if (process.env.REACT_APP_NODE_ENV === 'LOCAL') {
    orderUrl = `${process.env.FRONTEND_URL_LOCAL}/me/orders/${orderId}`;
  } else if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT') {
    orderUrl = `${process.env.FRONTEND_URL_TEST}/me/orders/${orderId}`;
  } else if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT_IN_PROGRESS') {
    orderUrl = `${process.env.FRONTEND_URL_TEST_IN_PROGRESS}/me/orders/${orderId}`;
  } else {
    orderUrl = `${process.env.FRONTEND_URL_LIVE}/me/orders/${orderId}`;
  } 

  const body = `
    Hello ${customerName},

    Your order #${orderNumber} has been ${action}.

    If you have any questions, feel free to reply to this email.

    Thank you for shopping with us.
  
    Tom Produce Team,
  `;

  const subject = `Order with Tom Produce: #${orderNumber}`;

  return { subject, body, orderUrl }
}

// --------------------------------------------------------------
export const getCurrentDateInPST = () => {
  return formatInTimeZone(new Date(), 'America/Los_Angeles', 'yyyy-MM-dd');
};

export const convertToPST = (date) => {
  return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'yyyy-MM-dd');
}
// --------------------------------------------------------------
export const getDateStartTimeInPST  = (date) => {
  return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'yyyy-MM-dd 00:00:00');
}

export const getDateEndTimeInPST  = (date) => {
  return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'yyyy-MM-dd 23:59:59');
}

// Function to format date to ISO string
export const formatDateToISOString = (date) => {
  formatInTimeZone(date, 'America/Los_Angeles', 'yyyy-MM-dd HH:mm:ssXXX') 
};

export const getCurrentTimeInPST = () => {
  return formatInTimeZone(new Date(), 'America/Los_Angeles', 'HH:mm:ss');
};

export const getPSTDate = () => {
  const timeZone = 'America/Los_Angeles';
  const currentDate = new Date();
  const pstDate = toZonedTime(currentDate, timeZone);
  return pstDate;
};

export const getPSTStartOfDay = (date) => {
  const timeZone = 'America/Los_Angeles';
  const currentDate = date ? date : new Date();
  const pstDate = toZonedTime(currentDate, timeZone);
  pstDate.setHours(0, 0, 0, 0);
  const offset = getTimezoneOffset(timeZone);
  return new Date(pstDate.getTime() - offset);
};
export const getPSTEndOfDay = () => {
  const timeZone = 'America/Los_Angeles';
  const currentDate = new Date();
  const pstDate = toZonedTime(currentDate, timeZone);
  pstDate.setHours(23, 59, 59, 999);
  const offset = getTimezoneOffset(timeZone);
  return new Date(pstDate.getTime() - offset);
};

export const getUTCDate = () => {
  const currentDate = new Date();
  const utcDate = new Date(currentDate.toISOString());
  return utcDate;
};

// After 6pm, inventory ballance is for tomorrow
export const isInventoryTrackingForTomorrow = () => {
  const currentTimeInPST = getCurrentTimeInPST();
  const hour = currentTimeInPST.split(':')[0];
  return hour >= 18;
}
// --------------------------------------------------------------

