import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { formatInTimeZone, toZonedTime, format, addDays } from 'date-fns-tz';
import { getHours } from 'date-fns';

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ConfirmDialog } from "../ConfirmDialog";
import { useAdminDeleteOrderMutation, useLazyAdminGetOrdersByDateQuery, useAdminForceResyncOneOrderQuickbooksMutation, useAdminForceResyncMultipleOrderQuickbooksMutation } from "../../../redux/api/orderAdminApi";
import { ADMIN_MENU_ITEMS, DELIVERY_TYPE, ORDER_STATUS, ORDER_STATUS_ICON, ORDER_USER_RIGHTS, QUICKBOOKS_CATEGORY, QUICKBOOKS_STATUS, USER_ROLES } from "../../../constants/constants.js";
import { CanUserCreateOrder, CanUserCreditOrder, DetermineUserOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { setAdminOptions } from "../../../redux/features/adminOptionsSlice";
import DatePicker from "react-datepicker";
import { useAdminGetAllUsersQuery } from "../../../redux/api/adminApi.js";
import { getDateEndTimeInPST, getDateStartTimeInPST } from "../../../utils/utilities";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";
import VerifiedIcon from "../../layout/VerifiedIcon.jsx";

const ListOrders = () => {  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [orderToBeDeleted, showDeleteOrderConfirmDialog] = useState(null);

  // Redux state
  const { unviewedNewOrder, unviewedUpdatedOrder, loadingAdmin, user, viewOrderHistory } = useSelector((state) => state.adminAuth);
  //const { inProgressOrderData } = useSelector((state) => state.inProgressOrders);
  const { adminOptions } = useSelector((state) => state.adminOptions);

  const [filterDate, setFilterDate] = useState();

  const [AdminGetOrdersByDate, {data: inProgressOrderDataByDate} ] = useLazyAdminGetOrdersByDateQuery(undefined, { refetchOnMountOrArgChange: true });
  const [adminDeleteOrder, { isLoading: isDeletingOrder, error: orderDeleteError, isSuccess: orderDeleteSuccess }] = useAdminDeleteOrderMutation();
  const [AdminForceResyncOneOrderQuickbooks, { data: quickbooksResyncData, isLoading: isForcingQuickbooksResync }] = useAdminForceResyncOneOrderQuickbooksMutation();
  const [AdminForceResyncMultipleOrderQuickbooks, { data: quickbooksResyncMultipleData, isLoading: isForcingQuickbooksResyncMultiple }] = useAdminForceResyncMultipleOrderQuickbooksMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const {data: allStaffs, isLoading: isLoadingStaff, error: errorLoadingStaff } = useAdminGetAllUsersQuery();

  const [orderData, setOrderData] = useState(null);
  const [sortField, setSortField] = useState();
  const [sortingOrder, setSortingOrder] = useState();
  const [sortedOrders, setSortedOrders] = useState([]);

  const [totalOrderCount, setTotalOrderCount] = useState({ });
  const [salesRefFilter, setSalesRefFilter] = useState("ALL");
  const [salesReps, setSalesReps] = useState([]);

  // Default view to in-progress orders if there is no previous selection
  const [orderFilter, setOrderFilter] = useState("ALL");
  const [selectedQBResyncOption, setSelectedQBResyncOption] = useState("All");
  // ---------------------------- Helper Functions ----------------------------
  function assignOrderData() {
    //console.log('inProgressOrderDataByDate', inProgressOrderDataByDate);
    // Filter orders by orderDate
    //const filterDateString = formatInTimeZone(filterDate, 'America/Los_Angeles', 'MM/dd/yyyy');
    const filteredOrderData = {orders : inProgressOrderDataByDate?.orders};//{orders : inProgressOrderDataByDate?.orders?.filter(order => order.orderDate === filterDateString)};
    if (salesRefFilter !== "ALL" && salesReps.length > 0) {
      const salesRep = salesReps.find(rep => rep._id === salesRefFilter);
      if (salesRep) {
        filteredOrderData.orders = filteredOrderData?.orders?.filter(order => order.customer.salesRep ? order.customer.salesRep.toString() === salesRep._id : order.createdBy === salesRep.name);
      }
    }

    const pendingCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.PENDING).length || 0;
    const confirmedCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.CONFIRMED).length || 0;
    const packingCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.PACKING).length || 0;
    const readyToShipCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.READY_TO_SHIP).length || 0;
    const shippedCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.SHIPPED).length || 0;
    const deliveredCount = filteredOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.DELIVERED).length || 0;
    const allCount = filteredOrderData?.orders?.length || 0;

    setTotalOrderCount({
      [ORDER_STATUS.PENDING]: pendingCount,
      [ORDER_STATUS.PROCESSING]: confirmedCount + packingCount + readyToShipCount,
      [ORDER_STATUS.SHIPPED]: shippedCount,
      [ORDER_STATUS.DELIVERED]: deliveredCount,
      ALL: allCount
    });

    switch (orderFilter) {
      case ORDER_STATUS.PENDING:
        const pendingOrderData = {orders : filteredOrderData?.orders?.filter(order => order.status === ORDER_STATUS.PENDING)};
        setOrderData(pendingOrderData);
        break;
      case ORDER_STATUS.PROCESSING:
        const processingOrderData = {orders : filteredOrderData?.orders?.filter(order => order.status ===  ORDER_STATUS.CONFIRMED || order.status ===  ORDER_STATUS.PACKING || order.status ===  ORDER_STATUS.READY_TO_SHIP)};
        setOrderData(processingOrderData);
        break;
      case ORDER_STATUS.SHIPPED:
        const shippedOrderData = {orders : filteredOrderData?.orders?.filter(order => order.status ===  ORDER_STATUS.SHIPPED)};
        setOrderData(shippedOrderData);
        break;
      case ORDER_STATUS.DELIVERED:
        const deliveredOrderData = {orders : filteredOrderData?.orders?.filter(order => order.status ===  ORDER_STATUS.DELIVERED)};
        setOrderData(deliveredOrderData);
        break;
      case "ALL":
        setOrderData(filteredOrderData);
        break;
      default:
        break;
    }    
  }

  // ---------------------------- Sorting Functions ----------------------------
  const handleSortOrderClick = (field) => {
    if (sortField === field) {
      setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortingOrder('asc');
    }
  };

  const handleSort = () => {
    const newSortedOrders = orderData?.orders ? [...orderData?.orders] : [];
    if (sortingOrder && sortField ) {  
      newSortedOrders.sort((a, b) => {
        if (sortingOrder === 'asc') {
          return customSortFunctions[sortField](a, b);
        } else {
          return customSortFunctions[sortField](b, a);
        }
      });
    }
    setSortedOrders(newSortedOrders);
  };

  const customSortFunctions = {
    orderStatus: (a, b) => {
      const statusOrder = [ORDER_STATUS.PENDING, ORDER_STATUS.CONFIRMED, ORDER_STATUS.PACKING, ORDER_STATUS.READY_TO_SHIP, ORDER_STATUS.SHIPPED, ORDER_STATUS.DELIVERED];
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
    },
    orderDate: (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
    deliveryTime: (a, b) => new Date(a.deliveredAt) - new Date(b.deliveredAt),
    customer: (a, b) => a.customerName.localeCompare(b.customerName),
  };

  useEffect(() => {
    handleSort();
  }, [sortingOrder, sortField, orderData]);
  //------------------------------------------------------------------------

  useEffect(() => {
    const todayDate =  toZonedTime(new Date(), 'America/Los_Angeles');
    const hours = getHours(todayDate);
    let adjustedDate = todayDate;
    if (hours >= 18) { // 6 PM is 18:00 in 24-hour format
      adjustedDate = new Date(todayDate.getTime() + 86400 * 1000); // 86400 seconds * 1000 milliseconds
    }
    setFilterDate(adjustedDate);
  }, []);

  useEffect(() => {
    if (filterDate) {
      AdminGetOrdersByDate({ startDate: getDateStartTimeInPST(filterDate), endDate: getDateEndTimeInPST(filterDate) } );
    }
  }, [filterDate]);

  useEffect(() => {
    if (adminOptions) {
      if (adminOptions.allOrdersStatusFilter) {
        setOrderFilter(adminOptions.allOrdersStatusFilter);
      }
      if (adminOptions.salesRep) {
        setSalesRefFilter(adminOptions.salesRep);
      }
      if (adminOptions.allOrdersDateFilter) {
        setFilterDate(adminOptions.allOrdersDateFilter);
      }
    }
  }, [adminOptions]);

  useEffect(() => {
    if (allStaffs) {
      // Filter out drivers
      setSalesReps(allStaffs.filter(staff => staff.role !== USER_ROLES.DRIVER));
    }
  }, [allStaffs]);

  useEffect(() => {
    assignOrderData();
  }, [inProgressOrderDataByDate]);

  useEffect(() => {
    if (salesReps) {
      assignOrderData();
    }
  }, [orderFilter, filterDate, salesRefFilter, salesReps]);

  useEffect(() => {
    if (orderDeleteError) {
      toast.error(orderDeleteError?.data?.message);
    }
    if (errorLoadingStaff) {
      toast.error(errorLoadingStaff?.data?.message);
    }

    /*
    if (orderDeleteSuccess) {
      toast.success("Order Removed");
    }*/
  }, [orderDeleteError, orderDeleteSuccess, errorLoadingStaff]);

  /*
  useEffect(() => {
    if (quickbooksResyncData && quickbooksResyncData.order && filteredOrderData?.orders) {
    }
  }, [quickbooksResyncData]);
  */

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (user === null || loadingAdmin || orderData === null || isLoadingStaff) {
    return <Loader />;
  }

  //--------------------------------- Functions ---------------------------------
  const handleFilterDateChange = (date) => {
    setFilterDate(date);

    const newAdminOptions = {...adminOptions, allOrdersDateFilter: date};
    dispatch(setAdminOptions(newAdminOptions));
  };

  const handleSalesRefFilterChange = (salesRep) => {
    setSalesRefFilter(salesRep);
    // Save selection
    const newAdminOptions = {...adminOptions, salesRep: salesRep};
    dispatch(setAdminOptions(newAdminOptions));
  }

  const handleOrderStatusChange = (status) => {
    setOrderFilter(status);
    // Save selection
    const newAdminOptions = {...adminOptions, allOrdersStatusFilter: status};
    dispatch(setAdminOptions(newAdminOptions));
  }

  // Delete order
  const handleDeleteOrder = (order) => {
    if (!isDeletingOrder && order !== null) showDeleteOrderConfirmDialog(order);
  }

  // Confirm deleting order
  const confirmDeletingOrder = () => {
    if (!isDeletingOrder && orderToBeDeleted !== null) {
      adminDeleteOrder(orderToBeDeleted._id);
      showDeleteOrderConfirmDialog(null);
    }
  }

  // Create new order
  const createNewOrderHandler = () => {
    // Redirect to create new order page
    navigate('/admin/orders/new');
  }

  // Create new order
  const createNewOrderCreditMemoHandler = () => {
    // Redirect to create new order page
    navigate('/admin/sellcredits/new');
  }

  const handleOrderRowClick = (id) => {
    const isHistory = inProgressOrderDataByDate?.isHistory;
    navigate(isHistory ? `/admin/oldorders/${id}` : `/admin/orders/${id}`);
  };

  const forceResynceOneOrder = (id) => {
    AdminForceResyncOneOrderQuickbooks({ id, isHistory: inProgressOrderDataByDate?.isHistory} );
  };

  const QBResyncOptionChange = (e) => {
    setSelectedQBResyncOption(e.target.value);
  };

  const executeResyncMultiple = () => {
    AdminForceResyncMultipleOrderQuickbooks({ body: { startDate: getDateStartTimeInPST(filterDate), endDate: getDateEndTimeInPST(filterDate), option: selectedQBResyncOption } });
  };

  const checkOrderStatus = (order) => {
    const isNew =  viewOrderHistory && viewOrderHistory[order._id] === undefined;
    const updated = viewOrderHistory && viewOrderHistory[order._id]?.timestamp < new Date(order.updatedAt).getTime();

    return {isNew, updated};
  }

  const canCreateNewOrder = CanUserCreateOrder(user);
  const canCreditOrder = CanUserCreditOrder(user);

  const setOrders = (isCR) => {
    const orders = {
      columns: [
        {
          label: <CenterAlignedCell value={""}> </CenterAlignedCell>,
          field: "id",
        },
        {
          label: <CenterAlignedCell value={isCR ? "Credit #" : "Order #"}> </CenterAlignedCell>,
          field: "orderNumber",
        },
        {
          label: (
            <div onClick={() => handleSortOrderClick('customer')}>
              <CenterAlignedCell value={"Customer"} />
            </div>
          ),
          field: "customer",
          sort: 'disabled', 
          onClick: () => handleSortOrderClick('customer'),
        },
        {
          label: (
            <div onClick={() => handleSortOrderClick('orderStatus')}>
              <CenterAlignedCell value={"Status"} />
            </div>
          ),
          field: "orderStatus",
          sort: 'disabled', 
          onClick: () => handleSortOrderClick('orderStatus'),
        },
        /*
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
        },
        */
        {
          label: (
            <div onClick={() => handleSortOrderClick('orderDate')}>
              <CenterAlignedCell value={"Date"} />
            </div>
          ),
          field: "orderDate",
          sort: 'disabled', 
          onClick: () => handleSortOrderClick('orderDate'),
        },
        /*
        {
          label: (
            <div onClick={() => handleSortOrderClick('deliveryTime')}>
              <CenterAlignedCell value={"Delivery Time"} />
            </div>
          ),
          field: "deliveryTime",
          sort: 'disabled', 
          onClick: () => handleSortOrderClick('deliverTime'),
        },
        */
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
        },
        {
          label: <CenterAlignedCell value={"Info"}> </CenterAlignedCell>,
          field: "info",
        },
      ],
      rows: [],
    };
    let index = 1;
    sortedOrders.forEach((order) => {
      if ((isCR && !order.isCR) || (!isCR && order.isCR)) {
        return;
      }
      // Check if user has rights to delete order
      const {rights} = DetermineUserOrderRights(user, order.status);
      const deleteable = rights?.some(right => right === ORDER_USER_RIGHTS.DELETE);
      const { isNew, updated } = checkOrderStatus(order);
      const customerName = `${order.customerName ? order.customerName : order.customer?.name}${order.extraName ? ` - ${order.extraName}` : ''}`;
      let orderStatus = String(order?.status).toUpperCase();
      if (order.deliverType === DELIVERY_TYPE.PICK_UP && order.status === ORDER_STATUS.SHIPPED){
        orderStatus = 'PICKED UP';
      } else if (order.deliverType === DELIVERY_TYPE.PICK_UP && order.status === ORDER_STATUS.DELIVERED){
        orderStatus = 'FINISHED';
      }
      const orderNumberString = order.isCR ? `CR-${order.orderNumber}`: order?.orderNumber;

      orders.rows.push({
        clickEvent: () => handleOrderRowClick(order._id),
        id: <CenterAlignedCell value = {index ++}></CenterAlignedCell>,
        orderNumber: <span style={{ color: order.isCR ? 'orange' : '' }}>{orderNumberString}</span>,
        customer: customerName,
        //paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
        orderStatus: order.isCR ? '' : 
                      <span style={{alignContent: 'center'}}>
                        { ORDER_STATUS_ICON[order.status] && (<i className={ORDER_STATUS_ICON[order.status].icon}  style={{ color: ORDER_STATUS_ICON[order.status].color }} /> )} {orderStatus}
                      </span>,
        orderDate: <CenterAlignedCell value={order?.orderDate} > </CenterAlignedCell>,
        /*deliveryTime: <CenterAlignedCell value={order?.deliveredAt ? new Date(order?.deliveredAt).toLocaleString()  : ''}> </CenterAlignedCell>,
        actions: (
          <center>
            { deleteable && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={(event) => { event.stopPropagation(); handleDeleteOrder(order) }}
              disabled={isDeletingOrder || orderToBeDeleted !== null }
            >
              <i className="fa fa-trash"></i>
            </button>)}
          </center>
        ),*/
        note: 
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {isNew && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', width: '100%' }}>New</div>
          )}

          {!isNew && updated && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'orange', width: '100%' }}>Updated</div>
          )}
        </div>,
        info:
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {order.verified  && (
            <VerifiedIcon  verified={ order.verified} />
          )}
          {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT) && !order.noQB &&  (
            <QuickbooksIcon  qbCategory={QUICKBOOKS_CATEGORY.ORDER} qbRef={order.orderNumber}  QBStatus={order.QBStatus} function1={() => forceResynceOneOrder(order._id)}/>
          )}
        </div>,
      });
    });

    return orders;
  };

  const appendOrderOption = (status) => {
    let totalUnviewedNewOrder = 0;
    let totalUnviewedUpdatedOrder = 0;
    let totalCount = totalOrderCount[status] ? totalOrderCount[status] : 0;
    /*
    if (status === 'ALL') {
      totalUnviewedNewOrder = (unviewedNewOrder[ORDER_STATUS.PENDING] ? unviewedNewOrder[ORDER_STATUS.PENDING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.CONFIRMED] ? unviewedNewOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.PACKING] ? unviewedNewOrder[ORDER_STATUS.PACKING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.READY_TO_SHIP] ? unviewedNewOrder[ORDER_STATUS.READY_TO_SHIP] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.SHIPPED] ? unviewedNewOrder[ORDER_STATUS.SHIPPED] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.DELIVERED] ? unviewedNewOrder[ORDER_STATUS.DELIVERED] : 0);
      totalUnviewedUpdatedOrder = (unviewedUpdatedOrder[ORDER_STATUS.PENDING] ? unviewedUpdatedOrder[ORDER_STATUS.PENDING] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] ? unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.PACKING] ? unviewedUpdatedOrder[ORDER_STATUS.PACKING] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.READY_TO_SHIP] ? unviewedUpdatedOrder[ORDER_STATUS.READY_TO_SHIP] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.SHIPPED] ? unviewedUpdatedOrder[ORDER_STATUS.SHIPPED] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.DELIVERED] ? unviewedUpdatedOrder[ORDER_STATUS.DELIVERED] : 0);
    } else if (status === ORDER_STATUS.PROCESSING) {
      totalUnviewedNewOrder = (unviewedNewOrder[ORDER_STATUS.CONFIRMED] ? unviewedNewOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.PACKING] ? unviewedNewOrder[ORDER_STATUS.PACKING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.READY_TO_SHIP] ? unviewedNewOrder[ORDER_STATUS.READY_TO_SHIP] : 0);
      totalUnviewedUpdatedOrder = (unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] ? unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] : 0) +
                                  (unviewedUpdatedOrder[ORDER_STATUS.PACKING] ? unviewedUpdatedOrder[ORDER_STATUS.PACKING] : 0) +
                                  (unviewedUpdatedOrder[ORDER_STATUS.READY_TO_SHIP] ? unviewedUpdatedOrder[ORDER_STATUS.READY_TO_SHIP] : 0);
    } else {
      totalUnviewedNewOrder = unviewedNewOrder[status] ? unviewedNewOrder[status] : 0;
      totalUnviewedUpdatedOrder = unviewedUpdatedOrder[status] ? unviewedUpdatedOrder[status] : 0;
    }
      */
    let optionString = `${String(status).toUpperCase()}`;
    //const unviewedNewString = totalUnviewedNewOrder > 0 ? `(${totalUnviewedNewOrder} New)` : "";
    //const unviewUpdatedString = totalUnviewedUpdatedOrder > 0 ? `(${totalUnviewedUpdatedOrder} Updates)` : "";
    //const rightString = `${unviewedNewString}`;//${unviewUpdatedString}`;
    if (totalCount > 0) {
      optionString = optionString + ` (${totalCount})`;
    }
    //const optionString = rightString.length > 0 ? `${leftString}\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0${rightString}` : leftString;

    return (
      <option value={status}>
        { optionString }
      </option>
    );
  }

  const nonCROrders = setOrders(false);
  const CROrders = setOrders(true);

  return (
    <>
      <MetaData title={`${String(orderFilter).toUpperCase()} Orders`} />
      <HeaderAdmin title={`${String(orderFilter).toUpperCase()} Orders`}  bg_color={"lightgreen"}/>
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 my-0">
          <div className="row my-3 col-12">
            {/* Filter by order status */}
            <div className="col-4">
              <div className="form-group" style={{ width: '100%' }}>
                <text className="text-start" style={{ display: 'block', width: '100%' }}>Order Status</text>
                <select
                  id="type_field"
                  className="form-select"
                  name="orderFilter"
                  value={orderFilter}
                  onChange={(e) => handleOrderStatusChange(e.target.value)}
                >
                  {appendOrderOption("ALL")}
                  {appendOrderOption(ORDER_STATUS.PENDING)}
                  {appendOrderOption(ORDER_STATUS.PROCESSING)}
                  {appendOrderOption(ORDER_STATUS.SHIPPED)}
                  {appendOrderOption(ORDER_STATUS.DELIVERED)}
                </select>
              </div>
            </div>

            {/* Filter by order date */}
            <div className="column col-4">
              <div className="form-group" style={{ width: '100%' }}>
                <label className="text-start" style={{ display: 'block', width: '100%' }}>Order Date</label>
                <DatePicker
                  selected={filterDate}
                  onChange={handleFilterDateChange}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            {/* Filter by customer handler */}
            <div className="col-4">
              <div className="form-group" style={{ width: '100%' }}>
                <text className="text-start" style={{ display: 'block', width: '100%' }}>Sales Rep.</text>
                <select
                  id="type_field"
                  className="form-select"
                  name="salesRepFilter"
                  value={salesRefFilter}  
                  onChange={(e) => handleSalesRefFilterChange(e.target.value)}
                >
                  <option key= 'all' value={'ALL'}>ALL</option>
                  {salesReps.map((rep) => (
                    <option key={rep._id} value={rep._id}>
                      {rep.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 row my-0">
              {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT ) && (
                <div className="col-7">
                  <select className="form-select"
                    aria-label="Quickbooks Resync"
                    onChange={ (e) => QBResyncOptionChange(e) }
                    selected={selectedQBResyncOption}
                    defaultValue="All"
                  >
                    <option key ={"all"} value="All">All</option>
                    <option key={"unsyncedonly"} value='NotSynced'>Un-Synced</option>
                    {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                      <option key={"alltime"} value='AllTime'>All Time</option>
                    )}
                  </select>
                </div>
              )}
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-5">
                  <button 
                      onClick={() => {executeResyncMultiple();}} 
                      className="btn btn-success"
                      style={{width: '100%'}}
                  >
                      QB Sync
                  </button>
                </div>
              )}  
            </div>
            <div className="col-6">
              <h1 className="text-center">{nonCROrders.rows.length} Orders</h1>
            </div>
            <div className="col-3 column">
              {/*New Order Button - rigjht */}
              <div>
                {canCreateNewOrder && (
                <button 
                  onClick={() => {createNewOrderHandler();}} 
                  className="btn btn-primary"
                  style={{width: '100%'}}>
                  Create New Order
                </button>)}         
              </div>    
              <div className="py-2">
                {/* Admin only */}
                { canCreditOrder && (
                  <button 
                    onClick={() => {createNewOrderCreditMemoHandler();}} 
                    className="btn"
                    style={{width: '100%', backgroundColor: 'orange'}}
                  >
                      New Credit Memo
                  </button>          
               )}
              </div>
            </div>
          </div>
          {/* Loader */}
          {(isForcingQuickbooksResync || isForcingQuickbooksResyncMultiple ) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable
            data={nonCROrders}
            className="px-10 myMDBDataListOrderTableRow"
            bordered
            striped
            hover
            noBottomColumns
            searching={true}
            paging={false}
          />

          { CROrders.rows.length > 0 && (
            <h1 className="text-center width-100">{CROrders.rows.length} Credit Memos</h1>
          )}
          {CROrders.rows.length > 0 && (
              <MDBDataTable
                data={CROrders}
                className="px-10 myMDBDataListOrderTableRow"
                bordered
                striped
                hover
                noBottomColumns
                searching={true}
                paging={false}
              />  
            )}
          <ConfirmDialog message={`Are you sure to delete order #${orderToBeDeleted?.orderNumber}?`} show={orderToBeDeleted !== null} confirm={()=>{confirmDeletingOrder()}} cancel={()=> {showDeleteOrderConfirmDialog(null)}}> </ConfirmDialog>
        </div>
      </AdminLayout>
    </>
  );
};

export default ListOrders;
