import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setInventoryDataOrdersByProduct, setLoadingInventoryDataOrdersByProduct, setInprogressOrderData } from "../features/inProgressOrdersSlice.js";
import { updateUserUnviewedOrders, setAdmin } from "../features/adminSlice.js";
import { ORDER_STATUS } from "../../constants/constants.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";
import { convertToPST } from "../../utils/utilities.js";
import { formatInTimeZone } from "date-fns-tz";
import _ from "mongoose-sequence";

export const orderAdminApi = createApi({
  reducerPath: "orderAdminApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1/admin"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Order", /*"OrderDelivered",*/ "OldOrder", "Inventory"],
  endpoints: (builder) => ({
    // New order
    adminCreateNewOrder: builder.mutation({
      query(body) {
        return {
          url: "/orders/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order"],
    }),
    // New credit memo
    adminCreateNewCreditMemo: builder.mutation({
      query(body) {
        return {
          url: "/sellcredits/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order"],
    }),
    // Order details
    adminOrderDetails: builder.query({
      query: ({ id, isOldOrder, extraParams}) => isOldOrder ? `/oldorders/${id}?extraParams=${extraParams}` : `/orders/${id}?extraParams=${extraParams}`,
      providesTags: (result, error, id) => [{ type: 'OldOrder', id }, { type: 'Order', id }],
      forceRefetch: ({ currentArg, previousArg }) => true, 
    }),
    // Dashboard sales
    adminGetDashboardSales: builder.query({
      query: ({ startDate, endDate }) => `/getsalesinrange/?startDate=${startDate}&endDate=${endDate}`,
    }),
    // Get in-progress orders
    adminGetInProgressOrders: builder.query({
      query: () => `/ordersinprogress`,
      providesTags: ["Order"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let collectionTimestamp = 0;
          const { data } = await queryFulfilled;

          // Convert orderDate of each item to yyyy-MM-dd format
          const formattedOrders = data.orders.map(item => {
            return {
              ...item,
              orderDate: formatInTimeZone(convertToPST(new Date(item.orderDate)), 'America/Los_Angeles', 'MM/dd/yyyy'),
            };
          });

          // Create a new data object with the updated orders
          const updatedData = {
            ...data,
            orders: formattedOrders
          };

          const orderStatsHash = {};
          // Convert to hash map - key is product id
          const ordersByProduct = updatedData.orders.reduce((acc, order) => {
            const timestamp = new Date(order.updatedAt).getTime();
            collectionTimestamp = Math.max(collectionTimestamp, timestamp);
            orderStatsHash[order._id] = {timestamp, status: order.status};
            const orderId = order._id;
            const orderNumber = order.orderNumber;
            const customer = order.customer;
            if (order.orderItems) {
              order.orderItems.forEach(item => {
                const productId = item.product.toString();
                if (!acc[productId]) {
                  acc[productId] = {total: 0, inProgressTotal: 0, orders: []}; 
                }
                acc[productId].orders.push({ orderId, orderNumber, customer, price: item.finalPrice, quantity: item.finalQuantity, note: item.note, status: order.status, updatedAt: order.updatedAt, _id: item._id });
                acc[productId].total += item.finalQuantity;
                const inProgressStatus = [ORDER_STATUS.PENDING, ORDER_STATUS.CONFIRMED, ORDER_STATUS.PACKING, ORDER_STATUS.READY_TO_SHIP];
                if (inProgressStatus.includes(order.status)) {
                  acc[productId].inProgressTotal += item.finalQuantity;
                }
              });
            }
            return acc;
          }, {});
          dispatch(setInprogressOrderData(updatedData));
          dispatch(setInventoryDataOrdersByProduct({ ordersByProduct, collectionTimestamp }));
          dispatch(setLoadingInventoryDataOrdersByProduct(false));
          dispatch(updateUserUnviewedOrders(orderStatsHash));
        } catch (error) {
            dispatch(setLoadingInventoryDataOrdersByProduct(false));
            console.log(error);
        }
      },
    }),
    // Get orders by date
    adminGetOrdersByDate: builder.query({
      query: ({startDate, endDate}) => ({
        url: "/ordersbydate",
        params: {
          startDate,
          endDate,
        },
      }),
      transformResponse: (response) => {
        // Transform the response data here
        return {...response, orders: response.orders.map(order => ({
          ...order,
          orderDate: formatInTimeZone(convertToPST(new Date(order.orderDate)), 'America/Los_Angeles', 'MM/dd/yyyy'),
        }))};
      },
      providesTags: ["Order", "OldOrder"],  
    }),
    /*
    adminGetOrderQBStatusByDate: builder.query({
      query: ({startDate, endDate}) => ({
        url: "/orderqbstatusbydate",
        params: {
          startDate,
          endDate,
        },
      }),
    }),
    */
    // Get old orders
    adminSearchOrders: builder.query({
      query: (params) => ({
        url: "/searchorders",
        params: {
          page: params?.page,
          extraParams: params?.extraParams,
          searchValue: params?.searchValue,
          startDate: params?.startDate,
          endDate: params?.endDate,
        },
      }),
      forceRefetch: ({ currentArg, previousArg }) => true, // Always refetch
    }),
    // Old Order details
    adminOldOrderDetails: builder.query({
      query: ({ id, extraParams}) => `/oldorders/${id}?extraParams=${extraParams}`,
      providesTags: (result, error, id) => [{ type: 'OldOrder', id }],
      forceRefetch: ({ currentArg, previousArg }) => true, // Always refetch
    }),
    // Update order
    adminUpdateOrder: builder.mutation({
      query({ id, isOldOrder, body }) {
        //console.log("Updating order ...", body);
        return {
          url: isOldOrder ? `/oldorders/${id}` : `/orders/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order", { type: 'OldOrder', id: result.order.id }, "OldOrder", "Inventory"],
    }),
    // Update old order
    adminUpdateOldOrder: builder.mutation({
      query({ id, body }) {
        //console.log("Updating order ...", body);
        return {
          url: `/oldorders/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'OldOrder', id: result.order.id }, "OldOrder"],
    }),
    // Deliver order - same endpoint as above, just different tag
    adminShipOrder: builder.mutation({
      query({ id, body }) {
        return {
          url: `/orders/${id}/ship`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order", "Inventory"],
    }),    
    // Deliver order - same endpoint as above, just different tag
    adminPickupOrder: builder.mutation({
      query({ id, body }) {
        return {
          url: `/orders/${id}/pickup`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order", "Inventory"],
    }),  
    // Deliver order - same endpoint as above, just different tag
    adminDeliverOrder: builder.mutation({
      query({ id, body }) {
        //console.log("Updating order ...", body);
        return {
          url: `/orders/${id}/deliver`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order"],
    }),    
    adminFinishPickedUpOrder: builder.mutation({
      query({ id, body }) {
        //console.log("Updating order ...", body);
        return {
          url: `/orders/${id}/finishpickedup`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order"],
    }),   
    /*
    // Transfer order to Quickbooks
    adminTransferToQuickbooks: builder.mutation({
      query({ id, body }) {
        return {
          url: `/orders/${id}/transfertoquickbooks`,
          method: "PUT",
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }],
    }),
    */
    adminForceResyncMultipleOrderQuickbooks: builder.mutation({
      query({ body }) {
        return {
          url: `/orders/forcequickbooksresync`,
          method: "PUT",
          params: {
            key: body.option,
            startDate: body.startDate,
            endDate: body.endDate,
          },
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }],
    }),
    adminForceResyncOneOrderQuickbooks: builder.mutation({
      query({ id, isHistory }) {
        return {
          url: `/orders/${id}/forcequickbooksresync`,
          method: "PUT",
          params: {
            isHistory,
          },
        };
      },
    }),
    // Delete order
    adminDeleteOrder: builder.mutation({
      query({ params }) {
        return {
          url: `/orders/${params.orderId}`,
          method: "DELETE",
          params: {
            isOldOrder: params.isOldOrder,
          },
        };
      },
      invalidatesTags: ["Order", "OldOrder"],
    }),
    /*
    // Delete order
    adminDeleteOldOrder: builder.mutation({
      query(id) {
        //console.log('Removing order ...', id)
        return {
          url: `/oldorders/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OldOrder"],
    }),
    */
    // Get orders by product Id
    adminGetOrdersByProduct: builder.query({
      query: (id) => `/ordersinprogress/product/${id}`,
      transformResponse: (result) => result.orders,
      providesTags: ["Order"],
    }),
    // Update orders by product Id
    adminUpdateOrdersByProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/ordersinprogress/product/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Remove order item by product Id
    adminRemoveOrderItemByProduct: builder.mutation({
      query({ productId, orderId }) {
        return {
          url: `/ordersinprogress/product/${productId}/remove/${orderId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Mark order as viewed
    adminMarkOrderAsViewed: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `/orders/${orderId}/markasviewed`,
          method: "PUT",
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          if (result && result.data?.data) {
            dispatch(setAdmin(result.data.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    adminSendOrderEmail: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `/orders/${orderId}/email`,
          method: "POST",
          body,
        };
      },
    }),
    adminUploadOrderDocumentImages: builder.mutation({
      query({ id, formData }) {
          return {
              url: `/orders/${id}/upload_documents`,
              method: "POST",
              body: formData,
          };
      },
      invalidatesTags: ["Order"],
    }),
    adminDeleteOrderDocumentImage: builder.mutation({
        query({ id, body }) {
            return {
                url: `/orders/${id}/delete_document`,
                method: "PUT",
                body,
            };
        },
        invalidatesTags: ["Order"],
    }),
    adminGenerateDocumentSignedURL: builder.query({
      query: ({ documentId}) => ({
        url: `/orders/getdocumenturl/${documentId}`,
      }),
    }),
  }),
});

export const {
  useAdminCreateNewOrderMutation,
  useAdminCreateNewCreditMemoMutation,
  useAdminOrderDetailsQuery,
  useAdminOldOrderDetailsQuery,
  useLazyAdminGetDashboardSalesQuery,
  useAdminUpdateOrderMutation,
  useAdminUpdateOldOrderMutation,
  useAdminShipOrderMutation,
  useAdminPickupOrderMutation,
  useAdminDeliverOrderMutation,
  useAdminFinishPickedUpOrderMutation,
  //useAdminTransferToQuickbooksMutation,
  useAdminForceResyncMultipleOrderQuickbooksMutation,
  useAdminForceResyncOneOrderQuickbooksMutation,
  useAdminDeleteOrderMutation,
  useAdminDeleteOldOrderMutation,

  useLazyAdminOrderDetailsQuery,
  useLazyAdminOldOrderDetailsQuery,

  useLazyAdminGetInProgressOrdersQuery,
  useLazyAdminGetOrdersByDateQuery,
  //useLazyAdminGetOrderQBStatusByDateQuery,
  useLazyAdminSearchOrdersQuery,

  useAdminGetOrdersByProductQuery,
  useAdminUpdateOrdersByProductMutation,
  useAdminRemoveOrderItemByProductMutation,

  useAdminMarkOrderAsViewedMutation,

  useAdminSendOrderEmailMutation,

  useAdminUploadOrderDocumentImagesMutation,
  useAdminDeleteOrderDocumentImageMutation,

  useAdminGenerateDocumentSignedURLQuery,
} = orderAdminApi;
