import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminDeleteCustomerMutation,
  useAdminGetAllCustomersQuery,
  useAdminForceResyncOneCustomerQuickbooksMutation,
  useAdminForceResyncMultipleCustomerQuickbooksMutation,
} from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, COMPANY_ADDRESS_PHONE, QUICKBOOKS_CATEGORY, USER_ROLES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanDeleteCustomer, CanUserCreateCustomer, CanUpdateCustomer } from "../AdminActionEligibilities";
import { CenterAlignedCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";

const ListCustomers = () => {
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const { data, isLoading, error } = useAdminGetAllCustomersQuery(undefined, { refetchOnMountOrArgChange: true });
  const [adminDeleteCustomer, { data: deletedCustomerData, error: deleteError, isLoading: isDeleteLoading, isSuccess: deletedCustomerSuccess }] = useAdminDeleteCustomerMutation();
  const [AdminForceResyncOneCustomerQuickbooks, {data: forcedQBSyncedData, isLoading: isForcingQBResync}] = useAdminForceResyncOneCustomerQuickbooksMutation();
  const [AdminForceResyncMultipleCustomerQuickbooks, { data: forcedQBMultipleSyncedData, isLoading: isForcingMultipleCustomerResync }] = useAdminForceResyncMultipleCustomerQuickbooksMutation();

  const [showingDeleteConfirmCustomerIndex, showDeleteCustomerConfirmDialog] = useState(-1);

  const [clonedData, setClonedData] = useState([]);

  const [selectedQBResyncOption, setSelectedQBResyncOption] = useState("All");
  /*
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  */
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (deletedCustomerSuccess) {
      toast.success(`Customer ${deletedCustomerData.customerName} deleted`);
    }
  }, [error, deleteError, deletedCustomerSuccess]);

  useEffect(() => {
    if (forcedQBMultipleSyncedData) {
      // refresh the page
      window.location.reload();
    }
  }, [forcedQBMultipleSyncedData]);

  useEffect(() => {
    if (forcedQBSyncedData && forcedQBSyncedData.customer) {
      // Fined matched product in clonedData
      const index = clonedData.findIndex((customer) => customer._id === forcedQBSyncedData.customer._id);
      if (index !== -1) {
        clonedData[index] = forcedQBSyncedData.customer;
        setClonedData([...clonedData]);
      }
    }
  }, [forcedQBSyncedData]);

  useEffect(() => {
    if (data) {
      setClonedData([...data]);
    }
  }, [data]);

  const confirmDeletingCustomer = () => {
    if (showingDeleteConfirmCustomerIndex >= 0) {
      adminDeleteCustomer(data[showingDeleteConfirmCustomerIndex]._id);
      showDeleteCustomerConfirmDialog(-1);
    }
  }

  const handleOneCustomerQuickbooksResync = (id) => {
    AdminForceResyncOneCustomerQuickbooks(id);
  };

  const QBResyncOptionChange = (e) => {
    setSelectedQBResyncOption(e.target.value);
  };

  const executeResyncMultiple = () => {
    AdminForceResyncMultipleCustomerQuickbooks({ option: selectedQBResyncOption });
  };

  const setCustomers = () => {
    const customers = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },  
        {
          label: <CenterAlignedCell value={"Email"}> </CenterAlignedCell>,
          field: "email",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Phone"}> </CenterAlignedCell>,
          field: "phone",
          sort: "asc",
        },   
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Tier"}> </CenterAlignedCell>,
          field: "tier",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    const canDelete = CanDeleteCustomer(user);
    const canUpdate = CanUpdateCustomer(user);

    clonedData?.forEach((customer, index) => {
      customers.rows.push({
        id: index + 1,
        name: customer.name,
        email: <CenterAlignedCell value={customer.email}> </CenterAlignedCell>,
        phone: <CenterAlignedCell value={customer.addressInfo.phone}> </CenterAlignedCell>,
        status: <CenterAlignedCell value={String(customer?.status).toUpperCase()}> </CenterAlignedCell>,
        tier: <CenterAlignedCell value={`Tier ${customer.tier}`}> </CenterAlignedCell>,
        actions: (
          <center>
            {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT) && (
              <QuickbooksIcon qbCategory={QUICKBOOKS_CATEGORY.CUSTOMER} qbRef={customer._id} QBStatus={customer?.QBStatus} function1={() => handleOneCustomerQuickbooksResync(customer?._id)}/>
            )}
            {
              canUpdate && (
                <Link
                  to={`/admin/customers/${customer?._id}`}
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              )
            }
            {
              canDelete && (
                <button
                  className="btn btn-outline-danger ms-2"
                  onClick={() => showDeleteCustomerConfirmDialog(index)}
                  disabled={isDeleteLoading}
                >
                  <i className="fa fa-trash"></i>
                </button>
              )
            }
          </center>
        ),
      });
    });

    return customers;
  };

  // Create new customer
  const createNewCustomerHandler = () => {
    // Redirect to create new customer page
    navigate("/admin/customers/new");
  }

  if (isLoading || loadingAdmin) return <Loader />;

  const canCreateCustomer = CanUserCreateCustomer(user);
  return (
    <>
      <MetaData title={"All Customers"} />
      <HeaderAdmin title={"All Customers"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.CUSTOMERS.name}>
        <div id = "customer_table" className="col-12 col-lg-11 my-0 offset-lg-0">
          <div className="row my-3 mx-2">
            <div className="col-3 row my-0">
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-7">
                  <select className="form-select"
                    aria-label="Quickbooks Resync"
                    onChange={ (e) => QBResyncOptionChange(e) }
                    selected={selectedQBResyncOption}
                    defaultValue="All"
                  >
                    <option key ={"all"} value="All">All</option>
                    <option key={"unsyncedonly"} value='NotSynced'>Un-Synced Only</option>
                  </select>
                </div>
              )}
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-5">
                  <button 
                      onClick={() => {executeResyncMultiple();}} 
                      className="btn btn-success"
                      style={{width: '100%'}}
                  >
                      QB Sync
                  </button>
                </div>
              )}  
            </div>
            <h1 className="col-6 text-center">{data?.length} Customers</h1>
            <div className="col-3">
              {/*New Order Button - rigjht */}
              {canCreateCustomer && (
              <button 
                onClick={() => {createNewCustomerHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Customer
              </button>)}             
            </div>
          </div>
           {/* Loader */}
           {(isForcingQBResync || isForcingMultipleCustomerResync ) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable 
            data={setCustomers()} 
            displayEntries={true}
            entriesOptions={[10, 20, 50, 100, 500]} 
            className="px-3 myMDBDataListCustomerTableRow" 
            bordered striped hover 
            noBottomColumns
          />
        </div>
        <ConfirmDialog message={`Are you sure to delete this customer, ${showingDeleteConfirmCustomerIndex >=0 ? data[showingDeleteConfirmCustomerIndex].name : ""} ?`} show={showingDeleteConfirmCustomerIndex >= 0} confirm={()=>{confirmDeletingCustomer()}} cancel={()=> {showDeleteCustomerConfirmDialog(-1)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ListCustomers;
