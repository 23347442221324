import React, { useEffect, useRef, useState } from "react";
import { QUICKBOOKS_STATUS } from "../../constants/constants";

const QuickbooksIcon = ({qbCategory, qbRef, QBStatus, function1}) => {
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        if (showPopup) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPopup]);

    const handleButtonClick = (e) => {
        e.stopPropagation();
        setShowPopup(!showPopup);
    };

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
          setShowPopup(false);
        }
    };

    const handleFunction1 = (e) => {
        e.stopPropagation();
        if (function1) {
            function1();
            setShowPopup(false);
        }
    };

    const handleViewLog = (e) => {
        e.stopPropagation();
        window.open("/admin/quickbookslog?category=" + qbCategory + "&ref=" + qbRef, "_blank");
    };

    const getQBIcon = (status) => {
        switch (status) {
            case undefined:
                return "/images/icons/quickbooks_icon_gray.png";
            case QUICKBOOKS_STATUS.ERROR:
                return "/images/icons/quickbooks_icon_red.png";
            case QUICKBOOKS_STATUS.OUTDATED:
            case QUICKBOOKS_STATUS.NOT_SYNCED:
            case QUICKBOOKS_STATUS.SYNCING:    
                return "/images/icons/quickbooks_icon_yellow.png";
            case QUICKBOOKS_STATUS.SYNCED:
                return "/images/icons/quickbooks_icon.png";
        }
        return "";
    }

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <button
                type="button"
                id="quicbooksButton"
                style={{
                    height: "2.5em",
                    verticalAlign: "middle",
                    width: "2.5em",
                    padding: "0.5em",
                    border: "none",
                    background: "none",
                }}
                onClick={(e) => handleButtonClick(e)}        
            >
                <img
                src= {getQBIcon(QBStatus)}
                style={{ height: "100%", width: "100%" }}
                />
            </button>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: "absolute",
                        top: "3em",
                        left: "0",
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        padding: "0.5em",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                        zIndex: 1000,
                        width: "300px",
                    }}
                    >
                    <button onClick={(e) => handleFunction1(e)} style={{ display: "block", marginBottom: "0.5em" }}>
                        Resync
                    </button>
                    <button onClick={(e) => handleViewLog(e)} style={{ display: "block", marginBottom: "0.5em" }}>
                        View Log
                    </button>
                </div>
            )}
        </div>
    );
};

export default QuickbooksIcon;
